import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession, IAuthenticationCallback } from 'amazon-cognito-identity-js'

let poolData = {
  UserPoolId: 'eu-north-1_bFINoiIjM',
  ClientId: '1smtt7h0qf058lajnsgh7520ij',
}

if (window.location.hostname.toLowerCase() === 'fleet.tdpreprod.io') {
  poolData = {
    UserPoolId: 'eu-north-1_694AVdhFM',
    ClientId: '1fkho1mtiro1l32q1hpkv49fep',
  }
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: CognitoUser | null = userPool.getCurrentUser()

export function getCurrentUser() {
  return currentUser;
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise<CognitoUserSession>((resolve, reject) => {
    currentUser!.getSession(function (err: Error | null, session: CognitoUserSession | null) {
      if (err) {
        reject(err);
      } else {
        resolve(session!);
      }
    })
  }).catch((err) => {
    throw err;
  })
}

export async function signUpUserWithEmail(username: string, email: string, password: string) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ]

    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise<CognitoUserSession>((resolve, reject) => {
    const authenticationData = {
      Username: username,
      Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, <IAuthenticationCallback>{
      onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
        resolve(session);
      },
      mfaRequired: (challengeName, challengeParameters) => {
        reject({ code: 'MfaRequiredException', user: currentUser, challengeName, challengeParameters });
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        reject({ code: 'NewPasswordRequired', user: currentUser, userAttributes, requiredAttributes });
      },
      onFailure: (err: any) => {
        reject(err)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function sendMfa(currentUser: CognitoUser, mfaCode: string) {
  return new Promise<CognitoUserSession>((resolve, reject) => {
    currentUser.sendMFACode(mfaCode, {
      onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
        resolve(session);
      },
      onFailure: (err: any) => {
        reject(err)
      }
    })
  })
  .catch((err) => {
    throw err
  });    
}

export function signOut() {
    return new Promise<void>((resolve, _) => {
        if (!currentUser) {
            resolve();
        }
        else {
            currentUser.signOut(() => {
                resolve();
            });
        }
    });
}

export async function getAttributes() {
  return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
    if (!currentUser) {
        reject("Not logged in");
    }
    else {
        currentUser.getUserAttributes((err?: Error, attributes?: CognitoUserAttribute[]) => {
        if (err) {
            reject(err)
        } else {
            resolve(attributes!)
        }
        });
    }
  }).catch((err) => {
    throw err
  });
}

export async function setAttribute(attribute: any) {
  return new Promise<string>((resolve, reject) => {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    if (!currentUser) {
        reject("Not logged in");
    }
    else {
        currentUser.updateAttributes(attributeList, (err?: Error, res?: string) => {
        if (err) {
            reject(err);
        } else {
            resolve(res!);
        }
        });
    }
  }).catch((err) => {
    throw err;
  })
}

export async function sendCode(username: string) {
  return new Promise<any>((resolve, reject) => {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise<string>((resolve, reject) => {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    });
  });
}

export async function completeNewPasswordChallenge(currentUser: CognitoUser, newPassword: string) {
  return new Promise<CognitoUserSession>((resolve, reject) => {
      currentUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: function(session) {
          resolve(session);
        },
        mfaRequired: (challengeName, challengeParameters) => {
          reject({ code: 'MfaRequiredException', user: currentUser, challengeName, challengeParameters });
        },
        onFailure: function(err) {
          reject(err);
        },
      });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise<"SUCCESS">((resolve, reject) => {
    if (!currentUser) {
        reject("Not logged in");
    }
    else {
        currentUser.changePassword(oldPassword, newPassword, function (err?: any, res?: "SUCCESS") {
            if (err) {
                reject(err)
            } else {
                resolve(res!)
            }
        });
    }
  });
}