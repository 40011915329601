import React, { useContext } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

import { Stack } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navigation from './containers/left-nav';
import AuthProvider, { AuthContext, AuthIsNotSignedIn, AuthIsSignedIn, IAuth } from './contexts/auth-context';
import AuthPage from './routes/auth/auth-page';
import ChangePassword from './routes/auth/change-password';
import RequestPasswordResetCode from './routes/auth/request-password-reset-code';
import SignIn from './routes/auth/signin';
import VerifyAccount from './routes/auth/verify-code';
import Dashboard from './routes/dashboard/dashboard';
import { JourneyDetails } from './routes/journeys/journey-details';
import { JourneyList } from './routes/journeys/journey-list';
import VehicleMap from './routes/map/map-page';
import { ReportsPage } from './routes/reports/reports';
import { VehicleList } from './routes/vehicles/vehicle-list';
import { getBackendUrl } from './lib/back-end';

initializeIcons();

type Props = {
  darkModeSelected: boolean;
  toggleThemePreference: () => void;
}

const SignInRoute: React.FunctionComponent = () => (
  <AuthPage>
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<RequestPasswordResetCode />} />
        <Route path="/reset-password" element={<ChangePassword reset />} />
        <Route path="/change-password" element={<ChangePassword reset={false} />} />
        <Route path="/verify-account" element={<VerifyAccount reset />} />
      </Routes>
    </BrowserRouter>
  </AuthPage>
);

/*
<Route path="/mfa" element={<EnterMfaCode />} />
      <Route path="/verify" element={<VerifyCode />} />
      <Route path="/requestcode" element={<RequestCode />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      */

const MainRoute: React.FunctionComponent<Props> = (props) => {

  const { sessionInfo }: IAuth = useContext(AuthContext);

  const wsLink = new WebSocketLink({
    uri: `${getBackendUrl("ws")}/graphql?Authorization=Bearer%20${sessionInfo?.accessToken || ''}`,
    options: {
      timeout: 10000,
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        const token = sessionInfo?.accessToken;
        return {
          authorization: token ? `Bearer ${token}` : "",
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      },
    },
  });

  const httpLink = new HttpLink({
    uri: `${getBackendUrl("http")}/graphql`,
    credentials: 'same-origin'    
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionInfo?.accessToken;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Stack horizontal>
        <Navigation darkModeSelected={props.darkModeSelected} toggleThemePreference={props.toggleThemePreference} />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/map" element={<VehicleMap />} />
            <Route path="/journeys" element={<JourneyList />} />
            <Route path="/journey/:operatingDay/:id" element={<JourneyDetails />} />
            <Route path="/vehicles" element={<VehicleList />} />
            <Route path="/reports" element={<ReportsPage />} />
          </Routes>
        </BrowserRouter>
      </Stack>
    </ApolloProvider>
  );
};

/*
<Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/vehicles" element={<FleetTrackerContextProvider><FleetTracker /></FleetTrackerContextProvider> } />
              */

export const App: React.FunctionComponent<Props> = (props) => {
  return (
    <AuthProvider>
      <AuthIsSignedIn>
        <MainRoute darkModeSelected={props.darkModeSelected} toggleThemePreference={props.toggleThemePreference} />
      </AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <SignInRoute />
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
};
