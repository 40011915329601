import { Page } from "../../components/page";
import { gql, useQuery, useSubscription } from '@apollo/client';
import { Text } from "@fluentui/react";

const VEHICLES_SUBSCRIPTION = gql`
    subscription { 
        onVehiclePosition {
            positions {
            lineNumber
            }
        }
    }
`;

export const VehicleMap: React.FunctionComponent<{}> = () => {
    const { data, loading } = useSubscription(VEHICLES_SUBSCRIPTION, { variables: {  } });

    return (
        <Page title="Map">
            <Text>Under construction. Please check back later.</Text>
            {!loading && data.commentAdded.content}
        </Page>
    );
};

export default VehicleMap;