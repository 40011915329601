import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { useState, useEffect, useContext } from 'react';
import * as cognito from '../lib/cognito';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IUser {
  id: string; // cognito:username = "f8d89734-a612-40bc-a0da-012a003e0c75"
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  phone_number: string;
  phone_number_verified: boolean;
  profile: string;
  website: string;
  zoneinfo: string;
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  userInfo?: IUser;
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  completeNewPasswordChallenge?: any;
  sendMfa?: any;
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

type Props = {
  children?: React.ReactNode;
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [userInfo, setUserInfo] = useState<IUser>();
  const [attrInfo, setAttrInfo] = useState([]);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
        });

        setUserInfo({
          id: session.idToken.payload['cognito:username'],
          email: session.idToken.payload.email,
          email_verified: session.idToken.payload.email_verified,
          family_name: session.idToken.payload.family_name,
          given_name: session.idToken.payload.given_name,
          phone_number: session.idToken.payload.phone_number,
          phone_number_verified: session.idToken.payload.phone_number_verified,
          profile: session.idToken.payload.profile,
          website: session.idToken.payload.website,
          zoneinfo: session.idToken.payload.zoneinfo
        });

        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`);
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`);
        // await setAttribute({ Name: 'website', Value: window.location.href });
        const attr: any = await getAttributes();
        setAttrInfo(attr);        
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    if (!sessionInfo.accessToken)
      getSessionInfo();
  }, [setAuthStatus, authStatus, sessionInfo.accessToken]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      const session = await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err
    }
  }

  async function signUpWithEmail(username: string, email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(username, email, password);
    } catch (err) {
      throw err;
    }
  }

  async function sendMfa(currentUser: CognitoUser, mfaCode: string) {
    try {
      const session = await cognito.sendMfa(currentUser, mfaCode);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err
    }
  }

  async function completeNewPasswordChallenge(currentUser: CognitoUser, newPassword: string) {
    try {
      const session = await cognito.completeNewPasswordChallenge(currentUser, newPassword);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err
    }
  }

  function signOut() {
    cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function verifyCode(username: string, code: string) {
    try {
      await cognito.verifyCode(username, code);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    userInfo,
    signUpWithEmail,
    signInWithEmail,
    sendMfa,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    completeNewPasswordChallenge,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider;