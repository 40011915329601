import { createTheme } from "@fluentui/react";
import { themes} from "./colors";
import componentOverrides from "./componentOverrides";

const createFluentTheme = (themePreference: string) => {
  const theme = createTheme({
    ...themes[themePreference],

  });

  theme.components = componentOverrides(theme);

  return theme;
};

export default createFluentTheme;
