import { CommandBar, DefaultButton, Dropdown, DropdownMenuItemType, FocusTrapZone, getFocusStyle, ICommandBarItemProps, Icon, IDropdownOption, IDropdownStyles, IList, Layer, List, mergeStyleSets, Overlay, Popup, ScrollToMode, Spinner, SpinnerSize, Stack, Text, ThemeContext } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import Placeholder from '../../components/placeholder';
import { AuthContext, IAuth } from '../../contexts/auth-context';
import { getBackendUrl } from '../../lib/back-end';
import { formatLatency, formatTimestamp } from '../../lib/date-utils';
import { roundToZeroDecimals } from '../../lib/number-utils';
import './log-viewer.css';

type Props = {
    journeyId: string;
}

const options: IDropdownOption[] = [
    { key: 'messagesHeader', text: 'Message types', itemType: DropdownMenuItemType.Header },
    { key: 'apc', text: 'Passenger counts' },
    { key: 'progress', text: 'Progress reports' },
    { key: 'position', text: 'Positions' },
    { key: 'assignment', text: 'Assignments' },
    { key: 'odometer', text: 'Odometer' },

    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'latencyHeader', text: 'Latencies', itemType: DropdownMenuItemType.Header },
    
    { key: 'intime', text: 'In time' },
    { key: 'late', text: 'Late' },

    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'resultsHeader', text: 'Delivery', itemType: DropdownMenuItemType.Header },

    { key: 'delivered', text: 'Delivered' },
    { key: 'notDelivered', text: 'Not delivered' },
    { key: 'notLogged', text: 'Not logged' },
];

type Filter = {
    apc: boolean;
    progress: boolean;
    position: boolean;
    assignment: boolean;
    odometer: boolean;

    delivered: boolean;
    intime: boolean;
    late: boolean;
    resent: boolean;
    notDelivered: boolean;
    notLogged: boolean;
}


export const LogViewer: React.FunctionComponent<Props> = (props) => {

    const theme = useContext(ThemeContext)!;
    const { palette, semanticColors, fonts } = theme;

    const popupStyles = mergeStyleSets({
        root: {
            background: 'rgba(0, 0, 0, 0.2)',
            bottom: '0',
            left: '0',
            position: 'fixed',
            right: '0',
            top: '0',
        },
        content: {
            background: palette.white,
            left: '50%',
            maxWidth: '800px',
            padding: '0 2em 2em',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        },
    });

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { minWidth: 300, maxWidth: 900 },
    };

    const defaultFilter = ['apc','progress','position','assignment','odometer','intime','late','delivered','notDelivered','notLogged'];

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any | undefined>(undefined);
    const [filteredRows, setFilteredRows] = useState<any | undefined>(undefined);
    const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
    const [selectedRow, setSelectedRow] = useState<any | undefined>(undefined);
    const [filter, setFilter] = useState<Filter>({ apc: true, progress: true, position: true, assignment: true, odometer: true, delivered: true, intime: true, late: true, resent: true, notDelivered: true, notLogged: true });
    const [currentErrorLine, setCurrentErrorLine] = useState<number>(0);
    const [highlightedMessageId, setHighlightedMessageId] = useState<string | undefined>(undefined);
    const listRef: React.RefObject<IList> = React.useRef(null);

    const { sessionInfo }: IAuth = useContext(AuthContext);

    const getData = async () => {
        const backEndUrl = `${getBackendUrl("http")}/logs/${props.journeyId}`;
        const token = sessionInfo?.accessToken;

        fetch(backEndUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (rows) {
                if (rows && rows.length > 0) {
                    for (let i = 1; i < rows.length; i++) {
                        var timestamp = new Date(rows[i].timestamp);
                        var previousTimestamp = new Date(rows[i - 1].timestamp);
                        var diff = timestamp.getTime() - previousTimestamp.getTime();
                        rows[i].tsDiff = diff;
                    }
                }                
                setData(rows);
                updateFilteredRows(rows, filter);
                setLoading(false);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const updateFilteredRows = (rows: any[], filter: Filter) => {
        let temp = [];
        
        if (rows && rows.length > 0) {
            for (const row of rows) {
                const status = determineStatus(row);
                const messageType = row.messageType;
                const messageSubType = row.messageSubType;

                if (!filter.late && status == 'late')
                    continue;
                if (!filter.intime && status == 'ok')
                    continue;

                if (!filter.delivered && row.deliveredToTF) {
                    if (filter.notLogged && !row.loggedInVehicle) {

                    }
                    else
                        continue;
                }
                if (!filter.notLogged && !row.loggedInVehicle)
                    continue;
                if (!filter.notDelivered && !row.deliveredToTF)
                    continue;

                if ((status === 'resend' || status === 'recreated') && !filter.resent)
                    continue;
                if (status === 'failed' && !filter.notDelivered)
                    continue;

                if (messageType === 'assignment' && !filter.assignment)
                    continue;
                if (messageType === 'progress' && !filter.progress)
                    continue;
                if (messageSubType === 'apc' && !filter.apc)
                    continue;
                if (messageSubType === 'position' && !filter.position)
                    continue;
                if (messageSubType === 'odometer' && !filter.odometer)
                    continue;
                temp.push(row);
            }
            
            for (let i = 1; i < temp.length; i++) {
                var timestamp = new Date(temp[i].timestamp);
                var previousTimestamp = new Date(temp[i - 1].timestamp);
                var diff = timestamp.getTime() - previousTimestamp.getTime();
                temp[i].tsDiff = diff;
            }
        }

        setFilteredRows(temp);
    };

    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 54,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                },
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            fonts.xLarge,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        ],
        itemIndex: {
            fontSize: fonts.small.fontSize,
            color: palette.neutralTertiary,
            marginBottom: 10,
        },
        chevron: {
            alignSelf: 'center',
            marginLeft: 10,
            color: palette.neutralTertiary,
            fontSize: fonts.large.fontSize,
            flexShrink: 0,
        },
    });

    const determineStatus = (item: any) => {
        if (item.deliveredToTF && item.loggedInVehicle) {
            switch ((item.deliveryResult as string).toLowerCase()) {
                case 'direct':

                    if (item.messageSubType === 'apc' && item.latency > 2000) {
                        return 'late';
                    }
                    else if (item.latency > 1000) {
                        return 'late';
                    }
                    else {
                        return 'ok';
                    }
                case 'resend':
                    return 'resend';
                case 'failed':
                    return 'failed';
                case 'recreated':
                    return 'recreated';
                default:
                    return item.deliveryResult;
            }
        }
        if (item.deliveredToTF && !item.loggedInVehicle) {
            return 'not-logged';
        }
        else if (!item.deliveredToTF) {
            return 'not-delivered';
        }
        return 'ok';
    };

    const formatStatus = (item: any) => {
        const status = determineStatus(item);
        switch (status) {
            case 'late': return 'Delivered late';
            case 'ok': return 'OK';
            case 'resend': return 'Retried';
            case 'failed': return 'Not sent';
            case 'not-logged': return 'Not logged';
            case 'not-delivered': return 'Not delivered to TF';
            case 'recreated': return 'Recreated';
            default: 
                return status;
        }
    };

    const chooseIconForRow = (item: any) => {
        switch (determineStatus(item)) {
            case 'ok':
                return <Icon iconName="CheckMark" />
            case 'late':
                return <Icon iconName="Warning" />
            case 'not-logged':
                return <Icon iconName="Error" />
            case 'not-delivered':
                return <Icon iconName="StatusErrorFull" />
            default:
                return <Icon iconName="Warning" />
        }
    };

    const formatPosition = (item: any) => {
        const position = JSON.parse(item.payload);

        return `${position.Latitude}, ${position.Longitude}, ${roundToZeroDecimals(+position.SpeedOverGround, 1)} km/h`;
    }

    const formatOdometer = (item: any) => {
        const odometer = JSON.parse(item.payload);
        return odometer.Distance;
    }

    const formatReport = (item: any) => {
        const arrival = JSON.parse(item.payload);
        return `${arrival.PlannedPointJourneyPatternPointNumber}#${arrival.PlannedPointVisitCount}`;
    };

    const formatApc = (item: any) => {
        const apc = JSON.parse(item.payload);
        return `${apc.JourneyPatternPointNumber}#${apc.PointVisitCount} +${apc.BoardingCount} -${apc.AlightingCount} = ${apc.OnboardCount}`;
    }

    const formatExtra = (item: any) => {
        const extra = JSON.parse(item.payload);
        return `${extra.PreviousPointJourneyPatternPointNumber}#${extra.PreviousPointVisitCount} - Distance: ${extra.DistancePastPreviousPointMeters} m`;
    }

    const formatPayload = (item: any) => {
        if (!item.loggedInVehicle)
            return 'Payload not available';
        switch (item.messageSubType) {
            case 'position': return formatPosition(item);
            case 'odometer': return formatOdometer(item);
            case 'arrival': return formatReport(item);
            case 'waiting': return formatReport(item);
            case 'departure': return formatReport(item);
            case 'apc': return formatApc(item);
            case 'extra': return formatExtra(item);
            case 'passage': return formatReport(item);
            default: return '';
        }
    };

    const statusText = (item: any) => {
        const status = determineStatus(item);
        switch (status) {
            case 'ok':
                return "OK";
            case 'late':
                return "Delivered late";
            case 'not-logged':
                return "Not logged in vehicle";
            case 'not-delivered':
                return "Not delivered to TF";
            case 'recreated':
                return "Recreated"
            default:
                return status;
        }
    }

    const scroll = (index: number): void => {
        const temp = filteredRows;
        const updatedSelectedIndex = Math.min(Math.max(index, 0), temp.length - 1);
        setCurrentErrorLine(index);
    
        listRef.current?.scrollToIndex(
          updatedSelectedIndex,
          idx => 29,
          ScrollToMode.auto,
        );
        listRef.current?.forceUpdate();
    };

    const rowStyles = mergeStyleSets({
        root: {
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            padding: '0.25rem',
            fontFamily: 'monospace',
            height: 'calc(29px - 0.5rem)',            
            
            '&.position.ok': {
                color: palette.black,
                opacity: 0.3
            },
            
            '&.late': {
                color: '#fff',
                backgroundColor: palette.yellowDark // '#8e8e29'
            },
            
            '&.not-logged': {
                color: '#fff',
                backgroundColor: '#8d3eb3'
            },
            
            '&.not-delivered': {
                color: '#fff',
                backgroundColor: palette.redDark // '#b52121'
            },
            
            '&.highlighted': {
                backgroundColor: 'rgb(16, 110, 190) !important',
                color: '#fff !important'
            },

            '&:hover': {
                color: palette.neutralDark,
                backgroundColor: palette.themeLighter,
                opacity: 1

            },
            '&.position.ok:hover': {
                color: palette.neutralDark,
                opacity: 1
            },
            '.icon': {
                padding: '0 12px',
                marginTop: '4px',
            },            
            '.message-sub-type': {
                width: '80px',
                fontWeight: '700',
            },
            '.position': {
                
                '.message-sub-type': {
                    fontWeight: 'normal'
                }
            },
            
            '.tsDiff': {
                width: '90px',
                textAlign: 'right',
                paddingRight: '1.2rem'
            },
            
            '.latency': {
                width: '90px',
                textAlign: 'right',
                paddingRight: '1.2rem',
            },
            
            '.timestamp': {
                width: '240px'
            },
            
            '.status': {
                width: '250px'
            }
        },
        '&:hover': {
            backgroundColor: 'red'
        }
    });

    const onRenderCell = (item: any, index: number | undefined): JSX.Element => {
        const isHighlighted = highlightedMessageId === item.messageId;
        return (
            <div className={`${rowStyles.root} ${item.messageSubType} ${determineStatus(item)} ${isHighlighted ? 'highlighted': ''}`}
                // className={`log-row ${item.messageSubType} ${determineStatus(item)} ${highlightedMessageId === item.messageId ? 'highlighted': ''}`} 
                data-is-focusable={true} onDoubleClick={() => {
                    setSelectedRow(item);
                    showPopup();
                }}>
                <div className="icon">
                    {chooseIconForRow(item)}
                </div>
                <div className="message-sub-type">
                    {item.messageSubType}
                </div>
                <div className="tsDiff">
                    {item.tsDiff && (<>+ {item.tsDiff}ms</>)}
                </div>
                <div className="latency">
                    {item.latency ? (<>{item.latency}ms</>) : (<>--</>)}
                </div>
                <div className="timestamp">
                    <span className="date">{formatTimestamp(new Date(item.timestamp))}</span>
                </div>
                <div className="status">{statusText(item)}</div>
                <div className="payload">
                    {formatPayload(item)}
                </div>
            </div>
        )
    };

    const goToPreviousError = () => {
        var temp = filteredRows;
        for (let i = currentErrorLine - 1; i >= 0; i--) {
            if (determineStatus(temp[i]) != 'ok') {
                setCurrentErrorLine(i);
                setHighlightedMessageId(temp[i].messageId);
                scroll(i);
                return;
            }
        }
    };

    const goToNextError = () => {
        var temp = filteredRows;
        for (let i = currentErrorLine + 1; i < temp.length; i++) {
            if (determineStatus(temp[i]) != 'ok') {
                setCurrentErrorLine(i);
                setHighlightedMessageId(temp[i].messageId);
                scroll(i);
                return;
            }
        }
    };

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'previous',
            text: 'Go to previous error',
            iconProps: { iconName: 'ChevronLeftSmall' },
            onClick: goToPreviousError
        },
        {
            key: 'next',
            text: 'Go to next error',
            iconProps: { iconName: 'ChevronRightSmall' },
            onClick: goToNextError
        },
    ];

    
    let selectedOptions = [...defaultFilter];

    const updateFilter = (key: string, selected: boolean) => {
        const temp = JSON.parse(JSON.stringify(filter));
        (temp as any)[key] = selected;
        setFilter(temp);
        updateFilteredRows(data, temp);
    }

    const addSelection = (key: string) => {
        updateFilter(key, true);
    };
    const removeSelection = (key: string) => {
        updateFilter(key, false);
    }

    if (loading) {
        return <Spinner size={SpinnerSize.large} />
    }
    else {
        return (
            <>            
                {isPopupVisible && (
                    <Layer>
                        <Popup
                            className={popupStyles.root}
                            role="dialog"
                            aria-modal="true"
                            onDismiss={hidePopup}
                        >
                            <Overlay onClick={hidePopup} />
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content}>
                                    <h2>{selectedRow.messageSubType}</h2>
                                    <pre style={{maxHeight: '400px', overflow: 'hidden auto', minWidth: '600px', minHeight: '240px'}} dangerouslySetInnerHTML={{ __html: syntaxHighlight(JSON.stringify(JSON.parse(selectedRow.payload), undefined, 4)) }} />
                                    <dl style={{minWidth: '500px'}}>
                                        <dt>Internal latency</dt>
                                        <dd>{formatLatency(selectedRow.internalLatency)} <small>(from sample to report)</small></dd>

                                        <dt>Routing latency</dt>
                                        <dd>{formatLatency(selectedRow.internalRoutingLatency)} <small>(from report to delivery start)</small></dd>                                        

                                        <dt>Delivery started</dt>
                                        <dd>{formatTimestamp(selectedRow.deliveryStartTimestamp)}</dd>

                                        <dt>Delivery completed</dt>
                                        <dd>{formatTimestamp(selectedRow.deliveryCompletedTimestamp)}</dd>

                                        <dt>Send duration</dt>
                                        <dd>{formatLatency(selectedRow.sendDuration)}</dd>

                                        <dt>Delivery result</dt>
                                        <dd>{formatStatus(selectedRow)}</dd>

                                        <dt>Total latency</dt>
                                        <dd>{formatLatency(selectedRow.latency)}</dd>
                                    </dl>
                                    <DefaultButton onClick={hidePopup}>Close</DefaultButton>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}

                {filteredRows && filteredRows.length > 0 && (<Text variant='large'>Messages ({filteredRows.length})</Text>)}

                <Stack horizontal style={{ alignItems: 'center', marginBottom: '1rem'}}>
                    <CommandBar
                            items={commandBarItems}
                            ariaLabel="Log actions"
                            primaryGroupAriaLabel="Log actions"
                            farItemsGroupAriaLabel="More actions"
                        />

                    <Dropdown
                        placeholder="Filter"
                        label=""
                        defaultSelectedKeys={defaultFilter}
                        multiSelect
                        options={options}
                        styles={dropdownStyles}
                        onChange={(e, i) => { 
                            if (i) {
                                if (i.selected) {
                                    addSelection(i.key as string);
                                } else {
                                    removeSelection(i.key as string);
                                }
                            }
                         }}
                    />
                </Stack>

                {(filteredRows && filteredRows.length > 0) ? (
                    <>
                    <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', padding: '0.25rem', fontWeight: 'bold' }}>
                        <div style={{ width: '38px'}}></div>
                        <div style={{ width: '80px'}}>Type</div>
                        <div style={{ width: '90px', textAlign: 'right', marginRight: '1.2rem' }}>Gap</div>
                        <div style={{ width: '90px', textAlign: 'right', marginRight: '1.2rem' }}>Latency</div>
                        <div style={{ width: '240px'}}>Timestamp</div>
                        <div style={{ width: '250px'}}>Status</div>
                        <div style={{ flex: '1'}}>Payload</div>
                    </div>
                    <div style={{ maxHeight: '50vh', overflow: 'hidden auto' }}>
                        <List items={filteredRows} onRenderCell={onRenderCell} componentRef={listRef} />
                    </div>
                    </>
                ) : 
                (<Placeholder>No messages to show.</Placeholder>)}
            </>
        );
    }
}

export function syntaxHighlight(json: string) {
    if (!json) return ""; //no JSON from response

    json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
            var cls = "number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "key";
                } else {
                    cls = "string";
                }
            } else if (/true|false/.test(match)) {
                cls = "boolean";
            } else if (/null/.test(match)) {
                cls = "null";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        }
    );
}