import { IButtonStyles, ITheme } from "@fluentui/react";

const overrides = (theme: ITheme) => ({
    PrimaryButton: {
      styles: {
        label: {
          // Not sure why i did this.
          // just doing it to do something
          color: theme.palette.themeLight
        }
      } as IButtonStyles
    }    
  });
  
export default overrides;
