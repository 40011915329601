import { gql, useQuery } from '@apollo/client';
import { DefaultPalette, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { HorizontalBarChart, IChartDataPoint, IChartProps, StackedBarChart } from '@fluentui/react-charting';
import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { BadgeList } from '../../components/badges/badge-list';
import { Page } from '../../components/page';
import './journey-details.css';
import { LogViewer } from './log-viewer';

const JOURNEY_QUERY = gql`
    query GetJourney($journeyId: String!) {
        getJourney(journeyId: $journeyId) {
            success
            journey {
                id
                operatingDay
                vehicleNumber
                lineNumber
                journeyNumber
                totalMessagesLogged
                totalMessagesDelivered
                totalMessagesInTime
                positionsLate
                stopsMade
                sla
                slaExpectedCount
                slaActualCount
                missingNonPositionMessages
                overallP95Latency
                signOn
                signOff
                plannedDeparture
                actualDeparture
                deviations
                stats {
                    statsKey
                    messagesLogged
                    messagesDelivered
                    messagesDeliveredInTime
                    messagesDeliveredLate
                    messagesNotDelivered
                    messagesDeliveredButNotLogged
                    minLatency
                    maxLatency
                    averageLatency
                    p95AvgLatency        
                }
            }
        }
    }
`;

interface JourneyStats {
    statsKey: string;
    messagesLogged: number;
    messagesDelivered: number;
    messagesDeliveredInTime: number;
    messagesDeliveredLate: number;
    messagesNotDelivered: number;
    messagesDeliveredButNotLogged: number;
    minLatency: number;
    maxLatency: number;
    averageLatency: number;
    p95AvgLatency: number;
}

const dlDtStyles = {

};

export const JourneyStats: React.FunctionComponent<{ criticalMessagesMissing: number, stats: JourneyStats[] }> = (props) => {
    const hideRatio: boolean[] = [true, false];

    let data: IChartProps[] = [];
    let overall;
    let positions: JourneyStats | undefined = undefined;
    let deliveredOkay = 0;

    const PositionsBar = (props: { inTime: number, late: number, missing: number }) => {

        let expected = props.inTime + props.late + props.missing;
        var inTimePerCent = props.inTime / expected;

        let deliveredColor = DefaultPalette.green;
        if (inTimePerCent > 0.99) {
            deliveredColor = DefaultPalette.green;
        } else if (inTimePerCent > 0.95) {
            deliveredColor = DefaultPalette.yellowDark;
        } else {
            deliveredColor = DefaultPalette.redDark;
        }

        const perCent = Math.trunc((props.inTime / expected) * 1000) / 10;

        let callout = `${props.inTime} delivered in time, ${props.late} delivered late, ${props.missing} missing`;
        let perCentText = `${perCent} %`;

        const points: IChartDataPoint[] = [
            { legend: 'In time', data: props.inTime, color: deliveredColor, xAxisCalloutData: callout, yAxisCalloutData: perCentText },
            { legend: 'Late', data: props.late, color: DefaultPalette.yellowDark, placeHolder: true, xAxisCalloutData: callout, yAxisCalloutData: perCentText },
            { legend: 'Missing', data: props.missing, color: DefaultPalette.neutralTertiaryAlt, placeHolder: true, xAxisCalloutData: callout, yAxisCalloutData: perCentText },
        ];

        const data: IChartProps = {
            chartData: points,
        };

        return (
            <div style={{ width: '600px' }}>
                <Stack>
                    <Stack horizontal>
                        <Text style={{ fontWeight: 700, fontSize: '12px', marginTop: '8px' }}>positions</Text>
                        <Text style={{ fontWeight: 700, fontSize: '12px', marginTop: '8px', textAlign: 'right', flex: 1 }}>{expected}</Text>
                    </Stack>
                    <StackedBarChart data={data} barHeight={8} hideLegend={true} />
                </Stack>
            </div>
        );
    };

    for (const stat of props.stats) {
        let barColor = DefaultPalette.green;

        const expected = stat.messagesDelivered + stat.messagesNotDelivered;

        let callout;
        const perCent = Math.trunc((stat.messagesDelivered / expected) * 1000) / 10;

        if (stat.statsKey === 'position') {
            positions = stat;
            if (perCent < 95)
                barColor = DefaultPalette.redDark;
            else if (perCent < 99)
                barColor = DefaultPalette.yellowDark;
            callout = `${stat.messagesDeliveredInTime} delivered in time, ${stat.messagesDeliveredLate} delivered late, ${stat.messagesNotDelivered} missing`;
            deliveredOkay += stat.messagesDeliveredInTime;
            continue;
        }
        else if (stat.statsKey === 'overall') {
            overall = stat;
            continue;
        }
        else {
            if (stat.messagesNotDelivered > 0)
                barColor = DefaultPalette.redDark;
            callout = `${stat.messagesDelivered} / ${expected} delivered (${stat.messagesNotDelivered} missing)`;
            deliveredOkay += stat.messagesDelivered;
        }

        data.push({
            chartTitle: stat.statsKey,
            chartData: [
                {
                    legend: stat.statsKey,
                    horizontalBarChartdata: { x: stat.messagesDelivered, y: expected },
                    color: barColor,
                    xAxisCalloutData: callout,
                    yAxisCalloutData: perCent.toString() + " %"
                }
            ]
        });
    }

    let overallScore;
    let overallStatus;

    if (overall) {
        var barColor = DefaultPalette.green;
        const expected = overall.messagesDelivered + overall.messagesNotDelivered;
        let callout = `${overall.messagesDelivered} / ${expected}`;

        const perCent = Math.trunc((deliveredOkay / expected) * 1000) / 10;

        overallStatus = 'good';

        if (props.criticalMessagesMissing > 0) {
            barColor = DefaultPalette.redDark;
            overallStatus = 'bad';
        }
        else if (positions) {
            const positionsPerCent = positions.messagesDeliveredInTime / (positions.messagesDelivered + positions.messagesNotDelivered);
            if (positionsPerCent < 0.95) {
                barColor = DefaultPalette.redDark;
                overallStatus = 'bad';
            }
            else if (positionsPerCent < 0.99) {
                overallStatus = 'poor';
                barColor = DefaultPalette.yellowDark;
            }
        }
        callout = `${overall.messagesDelivered} / ${expected} delivered (${overall.messagesDelivered - deliveredOkay} late, ${overall.messagesNotDelivered} missing, where ${props.criticalMessagesMissing} are critical)`;

        data.unshift({
            chartTitle: overall.statsKey,
            chartData: [
                {
                    legend: overall.statsKey,
                    horizontalBarChartdata: { x: overall.messagesDelivered, y: expected },
                    color: barColor,
                    xAxisCalloutData: callout,
                    yAxisCalloutData: perCent.toString() + " %"
                }
            ]
        });
        overallScore = perCent.toString() + " %";
    }

    let warning = '';
    if (props.criticalMessagesMissing)
        warning = `${props.criticalMessagesMissing} critical message(s) missing`;
    else if (positions) {
        const positionsPerCent = positions.messagesDeliveredInTime / (positions.messagesDelivered + positions.messagesNotDelivered);
        if (positionsPerCent < 0.95) {
            warning = 'Positions were below 95% SLA level';
        } else if (positionsPerCent < 0.99) {
            warning = 'Positions close to 95% SLA threshold';
        }
    }

    if (overallScore && overallStatus) {
        let overallStatusColor = DefaultPalette.green;
        switch (overallStatus) {
            case 'poor': overallStatusColor = DefaultPalette.yellow; break;
            case 'bad': overallStatusColor = DefaultPalette.redDark; break;
        }
        return (
            <Stack>
                <Stack horizontal style={{ maxWidth: '600px', alignItems: 'baseline' }}>
                    <Text className={`overallStatus`} style={{color: overallStatusColor}}>{overallScore}</Text>
                    { warning && (<Text variant="xLarge" style={{ textAlign: 'right', flex: 1, color: overallStatusColor }}>{warning}</Text>)}
                </Stack>

                <HorizontalBarChart culture={window.navigator.language} data={data} hideRatio={hideRatio} width={600} />
                {positions && (<PositionsBar inTime={positions!.messagesDeliveredInTime} late={positions!.messagesDeliveredLate} missing={positions!.messagesNotDelivered} />)}
            </Stack>
        );
    }
    else {
        return <HorizontalBarChart culture={window.navigator.language} data={data} hideRatio={hideRatio} width={600} />;
    }
}

export const JourneyDetails: React.FunctionComponent<{}> = (props) => {
    let { operatingDay, id } = useParams();

    const [showLogs, setShowLogs] = useState<boolean>(false);
    const [warningDismissed, setWarningDismissed] = useState<boolean>(false);

    const { data, loading, error } = useQuery(JOURNEY_QUERY, {
        variables: {
            journeyId: `${operatingDay}/${id}`
        }
    });

    const Loading = () => (
        <Spinner size={SpinnerSize.large} />
    );

    const formatDate = (value: any) => {
        if (!value)
            return "--";

        if (value === "0001-01-01T00:00:00.000Z")
            return "--";
        try {
            const date = new Date(value);
            return date.toLocaleDateString("sv-SE") + " " + date.toLocaleTimeString("sv-SE");
        }
        catch {
            return "Invalid date!";
        }
    };

    const maybeStillOngoing = () => {
        if (formatDate(new Date()).substring(0, 10) === operatingDay) {
            if (formatDate(data.getJourney.journey.signOff) === '--')
                return true;
            
            const signOff = new Date(data.getJourney.journey.signOff);
            if ((new Date().getTime() - signOff.getTime()) / 1000 * 3600 < 2) 
                return true;
        }
        return false;
    }

    const WarningMessage = () => (
        <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <MessageBar                        
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                onDismiss={() => setWarningDismissed(true)}
                dismissButtonAriaLabel="Close"
            >
            If the journey is still ongoing, these metrics may lag a bit behind. Logs are only updated once or twice every 5 minutes and metrics a few minutes after each log update.
            </MessageBar>
        </div>
    );

    const Display = () => (
        <Stack>
            
            {maybeStillOngoing() && !warningDismissed && (<WarningMessage />)}
            <Stack horizontal style={{flexFlow: 'wrap'}}>
                <Stack style={{marginBottom: "2rem"}}>
                    <dl style={{minWidth: '500px'}}>
                        <dt>ID</dt>
                        <dd>{operatingDay}/{id}</dd>

                        <dt>Sign on</dt>
                        <dd>{formatDate(data.getJourney.journey.signOn)}</dd>

                        <dt>Sign off</dt>
                        <dd>{formatDate(data.getJourney.journey.signOff)}</dd>

                        <dt>Planned start</dt>
                        <dd>{data.getJourney.journey.plannedDeparture}:00</dd>

                        <dt>Departure</dt>
                        <dd>{data.getJourney.journey.actualDeparture || '--'}</dd>

                        <dt>Line number</dt>
                        <dd>{data.getJourney.journey.lineNumber}</dd>

                        <dt>Journey number</dt>
                        <dd>{data.getJourney.journey.journeyNumber}</dd>

                        <dt>Vehicle number</dt>
                        <dd>{data.getJourney.journey.vehicleNumber}</dd>

                        <dt>Stops made</dt>
                        <dd>{data.getJourney.journey.stopsMade > 0 ? data.getJourney.journey.stopsMade : '--'}</dd>

                        <dt>Deviations</dt>
                        <dd><BadgeList variant="danger" badges={(data.getJourney.journey.deviations.split(','))} /></dd>
                    </dl>                    
                </Stack>
                <JourneyStats stats={data.getJourney.journey.stats} criticalMessagesMissing={data.getJourney.journey.missingNonPositionMessages} />            
            </Stack>
            
            {!showLogs && (
                <>
                <Text variant='large'>Messages</Text>
                <PrimaryButton iconProps={{iconName: "ChangeEntitlements"}} style={{maxWidth: '140px', marginTop: '1rem'}} onClick={() => setShowLogs(true)}>Show logs</PrimaryButton>
                </>
                
            )}
            {showLogs && (<LogViewer journeyId={operatingDay + '/' + id} />)}
        </Stack>
    ); // <Timeline />

    return (
        <Page title="Journey">
            {loading ? (<Loading />) : (<Display />)}
        </Page>
    );
}