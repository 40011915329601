import { Separator, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import React from "react";
import { Page } from "../../components/page";
import { ConsolidationReportChart, JourneyCountChart, LatenciesChart, MessageStatsQueryLoaded, MessageStatsQueryLoading, MessageStatsQueryProvider } from "../../components/widgets/message-statistics";

const Loading = () => (
  <Spinner size={SpinnerSize.large} />
);

const Dashboard: React.FunctionComponent = () => {

  /*
  <Text variant="xLarge">Sample widgets (numbers are fictional)</Text>
        <Stack horizontal {...gapStyles}>
          <NumberWidget unit="bytes" iconName="Database" value={12441541231} title="Datalake" />
          <NumberWidget variant="success" unit="per-second" value={624.1} unitText="msg/sec" title="Throughput" />
          <NumberWidget variant="warn" value={42} title="Alerts" />
          <NumberWidget variant="error" iconName="Error" value={42} title="Errors" />
        </Stack>  
  */

  return (
    <Page title="Dashboard">
        <Stack>
          <MessageStatsQueryProvider>
            <Text variant="xLarge" style={{ marginTop: '2rem' }}>Logs consolidation</Text>
            <MessageStatsQueryLoading>
              <Loading />
            </MessageStatsQueryLoading>
            <MessageStatsQueryLoaded>
              <ConsolidationReportChart />
            </MessageStatsQueryLoaded>

            <Separator />

            <Stack horizontal style={{ flexFlow: 'row wrap', alignItems: 'stretch' }}>
              <Stack style={{ minWidth: '400px', flex: '1 1 0' }}>
                <Text variant="xLarge" style={{ marginTop: '2rem' }}>Journey count</Text>
                <MessageStatsQueryLoading>
                  <Loading />
                </MessageStatsQueryLoading>
                <MessageStatsQueryLoaded>
                  <JourneyCountChart />
                </MessageStatsQueryLoaded>
              </Stack>
              <Stack style={{ minWidth: '400px', flex: '1 1 0' }}>
                <Text variant="xLarge" style={{ marginTop: '2rem' }}>Latencies</Text>
                <MessageStatsQueryLoading>
                  <Loading />
                </MessageStatsQueryLoading>
                <MessageStatsQueryLoaded>
                  <LatenciesChart />
                </MessageStatsQueryLoaded>
              </Stack>
            </Stack>
          </MessageStatsQueryProvider>
        </Stack>
    </Page>
  );
}

export default Dashboard;