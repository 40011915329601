import { IStackProps, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ErrorBar from '../../components/errorBar';
import { AuthContext } from '../../contexts/auth-context';

type FormValues = {
  email?: string;
}

const gapStyles: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { marginTop: '1rem' } },
};


const RequestPasswordResetCode: React.FunctionComponent<{}> = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState('');

  const history = useNavigate();
  const authContext = useContext(AuthContext);

  const sendCodeClicked = async (values: FormValues) => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.sendCode(values.email)
      history('../reset-password');
    } catch (err) {
      setError('Unknown user')
    }
    finally {
        setBusy(false);
    }
  }

  return (
    <Stack>
      <ErrorBar error={error} />
      <Text variant='large'>Request Password Reset Code</Text>
      <form onSubmit={handleSubmit(sendCodeClicked)} style={{marginTop: '0rem'}} >
        <Stack {...gapStyles}>
          <TextField type="email" autoFocus {...register("email", { required: true, maxLength: 80 })} placeholder="Email" errorMessage={errors?.email?.message as string} />
          <PrimaryButton type="submit" disabled={busy}>Send request</PrimaryButton>
          <Link to={'/'}>Cancel</Link>
        </Stack>
      </form>
    </Stack>
  );
};

export default RequestPasswordResetCode;