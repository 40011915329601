import { Text } from '@fluentui/react';
import React, {useState} from 'react';
import './placeholder.css';

type Props = {
    children?: React.ReactNode;
}


export const Placeholder: React.FunctionComponent<Props> = (props) => 
    (<Text className="placeholder">{props.children}</Text>);

export default Placeholder;