export const getBackendUrl = (scheme: string) => {
    const hostName = window.location.hostname;

    let uri = '';
    let protocolSuffix = 's';

    if (hostName === "localhost") {
        uri = 'localhost:5000';
        protocolSuffix = '';
    }
    else {
        uri = 'api.' + window.location.hostname.split('.').slice(1).join('.');
    }

    return `${scheme}${protocolSuffix}://${uri}`;
}