import { DefaultButton, IStackProps, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import ErrorBar from '../../components/errorBar';
import { AuthContext } from '../../contexts/auth-context';

type CredentialsFormValues = {
  email?: string;
  password?: string;
}

interface CodeFormValues {
  code?: string;
}

const gapStyles: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { marginTop: '1rem' } },
};

type MfaCodeFormProps = {
  busy: boolean;
  onSubmit: (values: CodeFormValues) => void;
  onCancel: () => void;
}

type InitialPasswordFormProps = {
  busy: boolean;
  onSubmit: (values: { password?: string }) => void;
}

type SignInFormProps = {
  busy: boolean;
  onSubmit: (values: CredentialsFormValues) => void;
}

const SignInForm: React.FunctionComponent<SignInFormProps> = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <Stack>
      <form onSubmit={handleSubmit(props.onSubmit)} style={{marginTop: '0rem'}} >
        <Stack {...gapStyles}>
          <TextField type="email" autoFocus {...register("email", { required: true, maxLength: 80 })} placeholder="Email" errorMessage={errors?.email?.message as string} />
          <TextField type="password" canRevealPassword revealPasswordAriaLabel="Show password" {...register("password", { required: true })} placeholder="Password" errorMessage={errors?.password?.message as string} />
          <PrimaryButton type="submit" disabled={props.busy}>Sign in</PrimaryButton>
          <Link to={'./forgot-password'}>Forgot your password?</Link>
        </Stack>
      </form>
    </Stack>
  );
};

const MultiFactoryAuthForm: React.FunctionComponent<MfaCodeFormProps> = (props) => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  
  return (
    <Stack>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Stack {...gapStyles}>
          <TextField type="password" autoFocus placeholder="Code" {...register("code", { required: true, maxLength: 6 })} />
          <PrimaryButton type="submit" disabled={props.busy}>Sign in</PrimaryButton>
          <DefaultButton type="button" disabled={props.busy} onClick={props.onCancel}>Cancel</DefaultButton>
        </Stack>
      </form>
    </Stack>
  );
};

const InitialPasswordForm: React.FunctionComponent<InitialPasswordFormProps> = (props) => {

  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  
  return (
    <Stack>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Stack {...gapStyles}>
        <TextField type="password" {...register("password", { required: true, maxLength: 80 })} placeholder="New Password" errorMessage={errors?.password?.message as string} />
          <TextField type="password" {...register("passwordConfirm", {
            required: true, maxLength: 80,
            validate: (val?: string) => {
              if (watch('password') != val) {
                return "Your passwords do no match";
              }
            }
          })} placeholder="Confirm new password" errorMessage={errors?.passwordConfirm?.message as string} />
          <PrimaryButton type="submit" disabled={props.busy}>Change password</PrimaryButton>          
        </Stack>
      </form>
    </Stack>
  );
};

const SignIn: React.FunctionComponent<{}> = () => {

  const [busy, setBusy] = useState<boolean>(false);
  const [inMfaMode, setInMfaMode] = useState<boolean>(false);
  const [inInitialPasswordMode, setInInitialPasswordMode] = useState<boolean>(false);
  const [mfaChallengeMedium, setmfaChallengeMedium] = useState<string>();
  const [mfaChallengeDestination, setmfaChallengeDestination] = useState<string>();
  const [currentUser, setCurrentUser] = useState<CognitoUser>();
  const [error, setError] = useState('');

  const history = useNavigate();

  const authContext = useContext(AuthContext);
  
  const signInClicked = async (values: CredentialsFormValues) => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.signInWithEmail(values.email, values.password);
      history('/');
    } catch (err: any) {
      if (err.code == 'NewPasswordRequired') {
        setCurrentUser(err.user);
        setInInitialPasswordMode(true);
      }
      if (err.code == 'MfaRequiredException') {
        setCurrentUser(err.user);
        setInMfaMode(true);
        setmfaChallengeMedium(err.challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM);
        setmfaChallengeDestination(err.challengeParameters.CODE_DELIVERY_DESTINATION);
      } else if (err.code === 'UserNotConfirmedException') {
        history('verify-account')
      } else {
        setError(err.message);
      }
    }
    finally {
      setBusy(false);
    }
  }

  const sendMfaCodeClicked = async (values: CodeFormValues) => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.sendMfa(currentUser, values.code);
      history('/');
    } catch (err: any) {
      setError(err.message)
    }
    finally {
      setBusy(false);
    }
  }

  const initialPasswordSetClicked = async (values: { password?: string}) => {
    setBusy(true);
    try {
      await authContext.completeNewPasswordChallenge(currentUser, values.password);
      signInClicked({ email: currentUser?.getUsername(), password: values.password });
    }
    catch (err: any) {
      if (err.code == 'MfaRequiredException') {
        setCurrentUser(err.user);
        setInMfaMode(true);
        setmfaChallengeMedium(err.challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM);
        setmfaChallengeDestination(err.challengeParameters.CODE_DELIVERY_DESTINATION);
      } else if (err.code === 'UserNotConfirmedException') {
        history('verify-account')
      } else {
        setError(err.message);
      }
    }
    finally {
      setBusy(false);
    }
  };

  return (
    <>
    <Text variant="large" style={{marginBottom: '.5rem'}}>{
      inMfaMode ? (<>Please enter the one-time code sent to you in {mfaChallengeMedium} to {mfaChallengeDestination}</>)
      : inInitialPasswordMode ? (<>You must change your password.</>)
      : 'Sign in with your application account'}</Text>
    <ErrorBar error={error} />
    {
      inMfaMode
        ? (<MultiFactoryAuthForm busy={busy} onSubmit={sendMfaCodeClicked} onCancel={() => setInMfaMode(false)}  />)
        : inInitialPasswordMode  
        ? (<InitialPasswordForm busy={busy} onSubmit={initialPasswordSetClicked} />)
        : (<SignInForm busy={busy} onSubmit={signInClicked} />)
    }
    </>
  );
}

export default SignIn;