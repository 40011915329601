import { DefaultButton, IStackProps, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorBar from '../../components/errorBar';
import { AuthContext } from '../../contexts/auth-context';

type Props = {
  reset: boolean;
}

type FormValues = {
  email?: string;
  code?: string;
  password?: string;
  passwordConfirm?: string;
}

const gapStyles: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { marginTop: '1rem' } },
};

const ChangePassword: React.FunctionComponent<Props> = (props) => {
  const authContext = useContext(AuthContext);

  const [resetCompleted, setResetCompleted] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState('');

  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const history = useNavigate();

  const changePasswordClicked = async (values: FormValues) => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.forgotPassword(values.email, values.code, values.password)
      setResetCompleted(true)
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
    finally {
      setBusy(false);
    }
  }

  const signInAgain = async () => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.signOut();
      history('/')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
    finally {
      setBusy(false);
    }
  }

  const changePassword = (
    <Stack>
      <ErrorBar error={error} />
      <Text variant='large'>{props.reset ? 'Reset your password' : 'Change your password'}</Text>
      <form onSubmit={handleSubmit(changePasswordClicked)} style={{ marginTop: '0rem' }} >
        <Stack {...gapStyles}>
          <TextField type="email" autoFocus {...register("email", { required: true, maxLength: 80 })} placeholder="Email" errorMessage={errors?.email?.message as string} />
          {props.reset ? (<TextField type="text" {...register("code", { required: true, maxLength: 80 })} placeholder="Code" errorMessage={errors?.code?.message as string} />) : (<></>)}
          <TextField type="password" {...register("password", { required: true, maxLength: 80 })} placeholder="Password" errorMessage={errors?.password?.message as string} />
          <TextField type="password" {...register("passwordConfirm", {
            required: true, maxLength: 80,
            validate: (val?: string) => {
              if (watch('password') != val) {
                return "Your passwords do no match";
              }
            }
          })} placeholder="Confirm password" errorMessage={errors?.passwordConfirm?.message as string} />
          <PrimaryButton type="submit" disabled={busy}>{props.reset ? 'Reset password' : 'Change password'}</PrimaryButton>
          <DefaultButton onClick={() => history(-1)}>Cancel</DefaultButton>
        </Stack>
      </form>
    </Stack>
  );

  const changePasswordCompleted = (
    <Stack {...gapStyles}>
      <Text>Your password has successfully been changed.</Text>
      <PrimaryButton type="button" onClick={signInAgain}>Sign in</PrimaryButton>
    </Stack>
  );

  return resetCompleted ? changePasswordCompleted : changePassword;
};

export default ChangePassword;