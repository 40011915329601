import { PartialTheme } from "@fluentui/react";

const dark2: PartialTheme = {
  palette: {
    themePrimary: '#91cfff',
    themeLighterAlt: '#06080a',
    themeLighter: '#172129',
    themeLight: '#2c3e4d',
    themeTertiary: '#577c99',
    themeSecondary: '#80b7e0',
    themeDarkAlt: '#9cd4ff',
    themeDark: '#acdbff',
    themeDarker: '#c2e4ff',
    neutralLighterAlt: '#323232',
    neutralLighter: '#313131',
    neutralLight: '#2f2f2f',
    neutralQuaternaryAlt: '#2c2c2c',
    neutralQuaternary: '#2a2a2a',
    neutralTertiaryAlt: '#282828',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#333333'
  }};

const dark = {
    palette: {
      themePrimary: '#3ea0d1',
      themeLighterAlt: '#030608',
      themeLighter: '#0a1a21',
      themeLight: '#13303f',
      themeTertiary: '#26607d',
      themeSecondary: '#378db8',
      themeDarkAlt: '#4fa9d6',
      themeDark: '#67b5dc',
      themeDarker: '#8bc7e5',
      neutralLighterAlt: '#3c393b',
      neutralLighter: '#3b383a',
      neutralLight: '#383537',
      neutralQuaternaryAlt: '#343233',
      neutralQuaternary: '#322f31',
      neutralTertiaryAlt: '#302e2f',
      neutralTertiary: '#f0f0f0',
      neutralSecondary: '#f2f2f2',
      neutralPrimaryAlt: '#f5f5f5',
      neutralPrimary: '#e8e7e8',
      neutralDark: '#fafafa',
      black: '#fcfcfc',
      white: '#3d3a3c',
    }
  };
  
  const light: PartialTheme = {
    semanticColors: {
      bodyBackground: "#fff",
      bodyText: "#000"
    },
    palette: {
      themePrimary: "#0078d4",
      themeLighterAlt: "#eff6fc",
      themeLighter: "#deecf9",
      themeLight: "#c7e0f4",
      themeTertiary: "#71afe5",
      themeSecondary: "#2b88d8",
      themeDarkAlt: "#106ebe",
      themeDark: "#005a9e",
      themeDarker: "#004578",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#3b3a39",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e"
    }
  };

  export const themes: any = { dark, light };
  
  // export const themes: { [name: string]: any } = { dark, light };
  