import { ApolloError, DocumentNode, gql, useQuery } from '@apollo/client';
import { DefaultPalette } from '@fluentui/react';
import { IChartProps, IDataPoint, LineChart, VerticalBarChart, VerticalStackedBarChart } from '@fluentui/react-charting';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { exportToCsv } from '../../lib/csv';
import { addDays, daysBetween, getIso8601Week, monthsBetween, weeksBetween } from '../../lib/date-utils';
import { formatCountAndPerCent } from '../../lib/number-utils';


export type MessageStatsQueryPeriod = 'OneBarPerDay' | 'OneBarPerWeek' | 'OneBarPerMonth';

export const MessageStatsQueryLoading = ({ children }: PropsWithChildren<{}>) => {
    const context = useContext(MessageStatsQueryContext);
    return <>{context?.loading ? children : null}</>
}

export const MessageStatsQueryLoaded = ({ children }: PropsWithChildren<{}>) => {
    const context = useContext(MessageStatsQueryContext);
    return <>{context?.loading ? null : children}</>
}

const CHART_QUERY_BY_DAY = gql`
  query GetJourneySummaryByDay($year: Int!, $month: Int!, $day: Int!, $count: Int!) {
    getJourneySummaryByDay(year: $year, month: $month, day: $day, count: $count) {
      dataPoints {
        periodType
        id
        journeyCount
        stats {
          statsKey
          messagesLogged
          messagesDelivered
          messagesDeliveredInTime
          messagesDeliveredLate
          messagesNotDelivered
          messagesDeliveredButNotLogged
          minLatency
          maxLatency
          averageLatency
          p95AvgLatency
        }
      }
    }
  }
`;

const CHART_QUERY_BY_WEEK = gql`
  query GetJourneySummaryByWeek($year: Int!, $weekNo: Int!, $count: Int!) {
    getJourneySummaryByWeek(year: $year, weekNo: $weekNo, count: $count) {
      dataPoints {
        periodType
        id
        journeyCount
        stats {
          statsKey
          messagesLogged
          messagesDelivered
          messagesDeliveredInTime
          messagesDeliveredLate
          messagesNotDelivered
          messagesDeliveredButNotLogged
          minLatency
          maxLatency
          averageLatency
          p95AvgLatency
        }
      }
    }
  }
`;

const CHART_QUERY_BY_MONTH = gql`
  query GetJourneySummaryByMonth($year: Int!, $month: Int!, $count: Int!) {
    getJourneySummaryByMonth(year: $year, month: $month, count: $count) {
      dataPoints {
        periodType
        id
        journeyCount
        stats {
          statsKey
          messagesLogged
          messagesDelivered
          messagesDeliveredInTime
          messagesDeliveredLate
          messagesNotDelivered
          messagesDeliveredButNotLogged
          minLatency
          maxLatency
          averageLatency
          p95AvgLatency
        }
      }
    }
  }
`;

type QueryVariables_ByDay = {
    year: number;
    month: number;
    day: number;
    count: number;
}

type QueryVariables_ByWeek = {
    year: number;
    weekNo: number;
    count: number;
}

type QueryVariables_ByMonth = {
    year: number;
    month: number;
    count: number;
}

type QueryVariables = QueryVariables_ByDay | QueryVariables_ByWeek | QueryVariables_ByMonth;

type QueryProps = {
    periodicity: MessageStatsQueryPeriod;
    queryText: DocumentNode;
    variables: QueryVariables;
}

type QueryCallback = (startDate: Date, endDate: Date, periodicity: MessageStatsQueryPeriod) => void;

type MessageStatsQueryContextType = {
    query: QueryCallback;
    download: () => void;
    loading: boolean;
    data: any | undefined;
    error?: ApolloError;
}

export const MessageStatsQueryContext = React.createContext<MessageStatsQueryContextType | undefined>(undefined);

export const MessageStatsQueryProvider: React.FunctionComponent<PropsWithChildren<{}>> = (props) => {

    const defaultAmountOfDays = 10;
    const defaultStartDate = addDays(-defaultAmountOfDays + 1);

    const [queryProps, setQueryProps] = useState<QueryProps>({
        periodicity: 'OneBarPerDay',
        queryText: CHART_QUERY_BY_DAY,
        variables: {
            year: defaultStartDate.getFullYear(),
            month: defaultStartDate.getMonth() + 1,
            day: defaultStartDate.getDate(),
            count: defaultAmountOfDays
        }
    });

    const { data, error, observable, loading } = useQuery(queryProps.queryText, { variables: queryProps.variables });

    const query = async (startDate: Date, endDate: Date, periodicity: MessageStatsQueryPeriod) => {
        switch (periodicity) {
            case 'OneBarPerDay':
                setQueryProps({
                    periodicity,
                    queryText: CHART_QUERY_BY_DAY,
                    variables: {
                        year: startDate.getFullYear(),
                        month: startDate.getMonth() + 1,
                        day: startDate.getDate(),
                        count: daysBetween(startDate, endDate)
                    }
                });
                break;
            case 'OneBarPerWeek':
                setQueryProps({
                    periodicity,
                    queryText: CHART_QUERY_BY_WEEK,
                    variables: {
                        year: startDate.getFullYear(),
                        weekNo: getIso8601Week(startDate),
                        count: weeksBetween(startDate, endDate)
                    }
                });
                break;
            case 'OneBarPerMonth':
                setQueryProps({
                    periodicity,
                    queryText: CHART_QUERY_BY_MONTH,
                    variables: {
                        year: startDate.getFullYear(),
                        month: startDate.getMonth() + 1,
                        count: monthsBetween(startDate, endDate)
                    }
                });
                break;
        }
    };

    const generateCsvHeader = (type: string) => {
        return [
            `${type} Logged`,
            `${type} Delivered`,
            `${type} Delivered In Time`,
            `${type} Delivered Late`,
            `${type} Not Delivered`,
            `${type} Not Logged`,
            `${type} Min Latency`,
            `${type} Max Latency`,
            `${type} Average Latency`,
            `${type} p95 Latency`];
    };

    const generateCsvColumns = (stats: any) => {
        return [
            stats.messagesLogged,
            stats.messagesDelivered,
            stats.messagesDeliveredInTime,
            stats.messagesDeliveredLate,
            stats.messagesNotDelivered,
            stats.messagesDeliveredButNotLogged,
            stats.minLatency,
            stats.maxLatency,
            stats.averageLatency,
            stats.p95AvgLatency
        ]
    };

    const download = () => {
        let csvRows = [];
        let extractedData = extractData(data);

        // Header row
        csvRows.push([
            'Date',
            'Score',
            'Journey Count',
            ...generateCsvHeader('Overall'),
            ...generateCsvHeader('Progress Reports'),
            ...generateCsvHeader('Passenger Counts'),
            ...generateCsvHeader('Odometer'),
            ...generateCsvHeader('Sign On'),
            ...generateCsvHeader('Sign Off'),
            ...generateCsvHeader('Positions')
        ]);

        // Data rows
        for (const day of extractedData) {
            let overall, odometer, apc, progress, signon, signoff, position;
            let expectedMessageCount = 0;
            let deliveredMessageCount = 0;

            // Gather the individual stat structures
            for (const stat of day.stats) {
                switch (stat.statsKey) {
                    case 'overall':
                        overall = stat;
                        break;
                    case 'odometer':
                        odometer = stat;
                        break;
                    case 'apc':
                        apc = stat;
                        break;
                    case 'progress':
                        progress = stat;
                        break;
                    case 'signon':
                        signon = stat;
                        break;
                    case 'signoff':
                        signoff = stat;
                        break;
                    case 'position':
                        position = stat;
                        break;
                }

                expectedMessageCount += stat.messagesDelivered + stat.messagesNotDelivered;
                if (stat.statsKey != 'position') {
                    deliveredMessageCount += stat.messagesDelivered;
                } else {
                    deliveredMessageCount += stat.messagesDeliveredInTime;
                }
            }

            // Push the data row
            csvRows.push([day.id,
            deliveredMessageCount / expectedMessageCount,
            day.journeyCount,
            ...generateCsvColumns(overall),
            ...generateCsvColumns(progress),
            ...generateCsvColumns(apc),
            ...generateCsvColumns(odometer),
            ...generateCsvColumns(signon),
            ...generateCsvColumns(signoff),
            ...generateCsvColumns(position)
            ]);
        }
        exportToCsv('report.csv', csvRows);
    };

    // GraphQL returns a JSON structure that contains the query name as one of its nodes
    const extractData = (values: any) => {
        let key = '';
        switch (queryProps.periodicity) {
            case 'OneBarPerDay':
                key = 'getJourneySummaryByDay';
                break;
            case 'OneBarPerWeek':
                key = 'getJourneySummaryByWeek';
                break;
            case 'OneBarPerMonth':
                key = 'getJourneySummaryByMonth';
                break;
        }
        // JSON: { "data": { "getJourneySummaryByDay": { "dataPoints": { <actual data here> } } } }
        if (values && values[key] && values[key].dataPoints)
            return values[key].dataPoints;
        else
            return undefined;
    }

    const contextValues = { loading, data: extractData(data), query, download };
    return (
        <MessageStatsQueryContext.Provider value={contextValues}>
            {props.children}
        </MessageStatsQueryContext.Provider>
    );
}

export const ConsolidationReportChart: React.FunctionComponent<{}> = (props) => {
    const context = useContext(MessageStatsQueryContext);
    const { width, height, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 100
    });

    return (
        <div ref={ref} style={{ maxHeight: '300px' }}>
            <VerticalStackedBarChart
                aria-label="Vertical Stacked Bar Chart" // no prop to support this
                data={formatMessageStatsBars(context?.data).messageStatsBars}
                width={width ? width - 60 : undefined} // -60 to account for chart margins
                height={height}
                maxBarWidth={32}
                barWidth={32}
                styles={{ chartWrapper: { overflow: 'hidden' } }}
            />
        </div>
    );
};

export const JourneyCountChart: React.FunctionComponent<{}> = (props) => {
    const context = useContext(MessageStatsQueryContext);

    const journeyCountBars = context?.data.map((dp: any) => ({
        x: dp.id,
        y: dp.journeyCount
    }));

    const { width, height, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 100
    });

    return (
        <div ref={ref} style={{ maxHeight: '300px' }}>
            <VerticalBarChart
                aria-label="Vertical Bar Chart" // no prop to support this
                data={journeyCountBars}
                hideLegend={true}
                colors={[DefaultPalette.blueLight]}
                useSingleColor={true}
                width={width ? width - 60 : undefined} // -60 to account for chart margins
                height={height}
                maxBarWidth={32}
                barWidth={32}
                styles={{ chartWrapper: { overflow: 'hidden' } }}
            />
        </div>);
}

function where<T>(arr: T[], selector: (item: T) => boolean): T | undefined {
    for (const item of arr) {
        if (selector(item)) {
            return item;
        }
    }
    return undefined;
}

function mapDataPoint(data: any, key: string) {
    return data.map((dp: any) => {
        return {
            x: new Date(dp.id),
            y: where(dp.stats, (s: any) => s.statsKey === key).averageLatency,
            xAxisCalloutData: dp.id,
            yAxisCalloutData: `${where(dp.stats, (s: any) => s.statsKey === key).averageLatency} ms`
        };
    });
}

export const LatenciesChart: React.FunctionComponent<{}> = (props) => {
    const context = useContext(MessageStatsQueryContext);
    const data = context?.data;

    let tickValues: Date[] = data.map((dp: any) => new Date(dp.id));

    let latencyData: IChartProps = {
        lineChartData: [
            {
                data: mapDataPoint(data, 'overall'),
                legend: 'Overall',
                color: DefaultPalette.blueLight,
            },
            {
                data: mapDataPoint(data, 'apc'),
                legend: 'Passenger counts',
                color: DefaultPalette.purpleLight
            },
            {
                data: mapDataPoint(data, 'odometer'),
                legend: 'Odometer',
                color: DefaultPalette.purpleDark
            },
            {
                data: mapDataPoint(data, 'progress'),
                legend: 'Progress reports',
                color: DefaultPalette.magentaLight
            },
            {
                data: mapDataPoint(data, 'position'),
                legend: 'Positions',
                color: DefaultPalette.orangeLighter
            },
            {
                data: mapDataPoint(data, 'signon'),
                legend: 'Sign ons',
                color: DefaultPalette.tealLight
            },
            {
                data: mapDataPoint(data, 'signoff'),
                legend: 'Sign offs',
                color: DefaultPalette.tealLight
            }
        ]
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    let firstRender = true;

    let timeout: NodeJS.Timeout | null = null;
    const updateDimensions = useCallback(() => {
        if (containerRef.current) {
            if (timeout) clearTimeout(timeout);

            timeout = setTimeout(() => {
                if (!containerRef.current) return;

                setDimensions({
                    width: containerRef.current.clientWidth - 60, // -60 to account for chart margins
                    height: containerRef.current.clientHeight
                });
                firstRender = false;
            }, firstRender ? 0 : 100);
        }
    }, []);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%', maxHeight: '300px' }}>
            <LineChart
                aria-label="Vertical Bar Chart" // no prop to support this
                data={latencyData}
                strokeWidth={2}
                tickValues={tickValues}
                tickFormat={"%Y-%m-%d"}
                width={dimensions.width}
                height={dimensions.height}
                styles={{ chartWrapper: { overflow: 'hidden' }, }}

                legendProps={{
                    canSelectMultipleLegends: true
                }}
            />
        </div>
    );
}


function formatMessageStatsBars(data: any) {
    let messageStatsBars = [];
    let journeyCountBars: IDataPoint[] = [];

    for (const dataPoint of data) {
        let overall;
        for (const stats of dataPoint.stats) {
            if (stats.statsKey === 'overall') {
                overall = stats;
                break;
            }
        }

        let messageStatsBar = [];
        messageStatsBar.push({
            legend: 'In time',
            data: overall.messagesDeliveredInTime,
            color: DefaultPalette.blueLight,
            xAxisCalloutData: dataPoint.id,

            yAxisCalloutData: formatCountAndPerCent(overall.messagesDeliveredInTime, overall.messagesDelivered)
        });

        messageStatsBar.push({
            legend: 'Late',
            data: overall.messagesDeliveredLate,
            color: DefaultPalette.yellowLight,
            xAxisCalloutData: dataPoint.id,
            yAxisCalloutData: formatCountAndPerCent(overall.messagesDeliveredLate, overall.messagesDelivered)
        });

        messageStatsBar.push({
            legend: 'Not logged',
            data: overall.messagesDeliveredButNotLogged,
            color: DefaultPalette.purpleLight,
            xAxisCalloutData: dataPoint.id,
            yAxisCalloutData: formatCountAndPerCent(overall.messagesDeliveredButNotLogged, overall.messagesDelivered)
        });

        messageStatsBar.push({
            legend: 'Not delivered',
            data: overall.messagesNotDelivered,
            color: DefaultPalette.red,
            xAxisCalloutData: dataPoint.id,
            yAxisCalloutData: formatCountAndPerCent(overall.messagesNotDelivered, overall.messagesDelivered)
        });

        messageStatsBars.push({ chartData: messageStatsBar, xAxisPoint: dataPoint.id });

        journeyCountBars.push({
            x: dataPoint.id,
            y: dataPoint.journeyCount,
            // color: DefaultPalette.blueLight,
        });
    }

    return { messageStatsBars, journeyCountBars, data };
}