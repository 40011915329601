import dayjs from 'dayjs';
import 'dayjs/locale/sv';

export function addDays(numOfDays: number) {
  var today = new Date();
  var result = today.setDate(today.getDate() + numOfDays);
  return new Date(result);
}

// Determine the week of the year (local timezone) based on the ISO 8601 definition.
export function getIso8601Week(value: Date) {
  // Create a copy of the current date, we don't want to mutate the original
  const date = new Date(value.getTime());

  // Find Thursday of this week starting on Monday
  date.setDate(date.getDate() + 4 - (date.getDay() || 7));
  const thursday = date.getTime();

  // Find January 1st
  date.setMonth(0); // January
  date.setDate(1);  // 1st
  const jan1st = date.getTime();

  // Round the amount of days to compensate for daylight saving time
  const days = Math.round((thursday - jan1st) / 86400000); // 1 day = 86400000 ms
  return Math.floor(days / 7) + 1;
};

export function getDateString(value?: Date | null | undefined) {
  const temp = value || new Date();
  return temp.toLocaleDateString("sv-SE");
}

export function getNumberOfDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

export function daysBetween(startDate: Date, endDate: Date) {
  let days = endDate.getTime() - startDate.getTime();
  days = Math.trunc(days / (1000 * 3600 * 24)) + 1;
  days = Math.max(1, days);
  return days;
}

export function monthsBetween(startDate: Date, endDate: Date) {
  let left = (startDate.getFullYear() * 12) + startDate.getMonth();
  let right = (endDate.getFullYear() * 12) + endDate.getMonth();
  return (right - left) + 1;
}

export function weeksBetween(startDate: Date, endDate: Date) {
  return Math.ceil(daysBetween(startDate, endDate) / 7);
}

const formatWithoutTimezone = 'YYYY-MM-DD HH:mm:ss.SSS';
const formatWithTimezone = 'YYYY-MM-DD HH:mm:ss.SSS Z';

dayjs.locale('sv');

export const formatTimestamp = (timestamp?: Date | string, includeTimeZone?: boolean | undefined) => {
    if (!timestamp) 
        return '--';
    let temp: Date;
    if (timestamp instanceof Date) {
        temp = timestamp as Date;
    }
    else {
        temp = new Date(timestamp);
    }

    return dayjs(temp).format(includeTimeZone === false ? formatWithoutTimezone : formatWithTimezone);
}

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

export function formatLatency(milliseconds?: number) {
  if (milliseconds === null || milliseconds === undefined)
    return '--';
  let result = '';
  if (milliseconds > day) {
    var days = Math.floor(milliseconds / day);
    result = `${days}d `;
    milliseconds = milliseconds % day;
  }

  if (milliseconds > hour) {
    var hours = Math.floor(milliseconds / hour);
    result += `${hours}h `;
    milliseconds = milliseconds % hour;
  }

  if (milliseconds > minute) {
    var minutes = Math.floor(milliseconds / minute);
    result += `${minutes}m `;
    milliseconds = milliseconds % minute;
  }

  if (milliseconds > 2 * second) {
    var seconds = Math.floor(milliseconds / second);
    result += `${seconds}s `;
    milliseconds = milliseconds % second;
  }

  if (milliseconds != 0 || result.length == 0) {
    result += `${milliseconds}ms`;
  }
  
  return result.replace("  ", " ").trim();
}