import { CommandBar, DatePicker, DateRangeType, DayOfWeek, defaultDatePickerStrings, Dropdown, ICommandBarItemProps, IDropdownOption, IIconProps, IStackProps, IStackStyles, mergeStyleSets, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Page } from "../../components/page";
import { ConsolidationReportChart, MessageStatsQueryLoaded, MessageStatsQueryLoading, MessageStatsQueryPeriod, MessageStatsQueryContext, MessageStatsQueryProvider, JourneyCountChart, LatenciesChart } from "../../components/widgets/message-statistics";
import { addDays, daysBetween } from "../../lib/date-utils";

const datePickerStyles = mergeStyleSets({
    root: {
        minWidth: '300px',
        // selectors: { '> *': { marginBottom: 15 } } 
    },
    control: {
        width: '300px',
        // marginBottom: 15 
    },
});

const searchFormColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { a: '1' } },
};

type QueryValues = {
    periodicity?: MessageStatsQueryPeriod;
    startDate?: string;
    endDate?: string;
};

const periodicityDropdownOptions: IDropdownOption[] = [
    { key: 'OneBarPerDay', text: 'Day', selected: true },
    { key: 'OneBarPerWeek', text: 'Week' },
    { key: 'OneBarPerMonth', text: 'Month' }
];

// Wrapper around the page, with context provider
export const ReportsPage: React.FunctionComponent<{}> = () => {
    return (
        <MessageStatsQueryProvider>
            <ConsolidationReportViewer />
        </MessageStatsQueryProvider>
    )
};

const Loading = () => (
    <Spinner size={SpinnerSize.large} />
  );
  

export const ConsolidationReportViewer: React.FunctionComponent<{}> = () => {
    const { handleSubmit, control } = useForm();
    const context = useContext(MessageStatsQueryContext);
    const [periodicity, setPeriodicity] = useState<MessageStatsQueryPeriod>('OneBarPerDay');
    const [forceFullWidth, setForceFullWidth] = useState<boolean>(false);

    const defaultValues = {
        startDate: addDays(-7),
        endDate: new Date(),
        period: 'OneBarPerDay'
    };

    const search = async (values: QueryValues) => {
        let startDate = (values.startDate ? new Date(values.startDate!) : defaultValues.startDate);
        let endDate = (values.endDate ? new Date(values.endDate!) : defaultValues.endDate);
        context?.query(startDate, endDate, values.periodicity || 'OneBarPerDay');
        const numberOfDays = daysBetween(startDate, endDate);
        setForceFullWidth(numberOfDays > 14);        
    }

    const commandBarItems: ICommandBarItemProps[] = [
        {
            key: 'download',
            text: 'Download',
            iconProps: { iconName: 'Download' },
            onClick: () => context?.download(),
        },
    ];

    const forceFullWidthStyle = {
        display: 'flex',
        flexFlow: 'column'
    }

    const allowSplitWidthStyle = {
        display: 'flex',
        flexFlox: 'row wrap !important',
        alignItems: 'stretch'
    };

    return (
        <Page title="Reports">
            <Stack>
                <Stack horizontal style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                    <form onSubmit={handleSubmit(search)}>
                        <Stack horizontal {...searchFormColumnProps}>
                            <Controller
                                render={({ field }) => (
                                    <Dropdown
                                        style={{ width: '140px' }}
                                        placeholder="Periodicity"
                                        options={periodicityDropdownOptions}
                                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                                            setPeriodicity((option?.key || 'OneBarPerDay') as MessageStatsQueryPeriod);
                                            field.onChange(option?.key || 'OneBarPerDay');
                                        }}
                                    />
                                )}
                                control={control}
                                name="periodicity"
                            />

                            <Controller
                                render={({ field }) => (
                                    <DatePicker
                                        onSelectDate={(date) => field.onChange(date?.toISOString())}
                                        value={(field.value && new Date(field.value)) || defaultValues.startDate}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        showWeekNumbers={true}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={false}
                                        highlightSelectedMonth={true}
                                        highlightCurrentMonth={true}
                                        calendarProps={{ dateRangeType: periodicity === 'OneBarPerWeek' ? DateRangeType.Week : periodicity === 'OneBarPerMonth' ? DateRangeType.Month : DateRangeType.Day }}
                                        initialPickerDate={defaultValues.startDate}
                                        placeholder="Start date"
                                        ariaLabel="Start date"
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        styles={datePickerStyles}
                                    />
                                )}
                                control={control}
                                name="startDate"
                            />
                            <Controller
                                render={({ field }) => (
                                    <DatePicker
                                        onSelectDate={(date) => field.onChange(date?.toISOString())}
                                        value={(field.value && new Date(field.value)) || defaultValues.endDate}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        showWeekNumbers={true}
                                        firstWeekOfYear={1}
                                        showMonthPickerAsOverlay={false}
                                        highlightSelectedMonth={true}
                                        highlightCurrentMonth={true}
                                        calendarProps={{ dateRangeType: periodicity === 'OneBarPerWeek' ? DateRangeType.Week : periodicity === 'OneBarPerMonth' ? DateRangeType.Month : DateRangeType.Day }}
                                        initialPickerDate={defaultValues.endDate}
                                        placeholder="End date"
                                        ariaLabel="End date"
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        styles={datePickerStyles}
                                    />
                                )}
                                control={control}
                                name="endDate"
                            />
                            <PrimaryButton type="submit" >
                                Search
                            </PrimaryButton>
                        </Stack>
                    </form>
                    <CommandBar
                        items={commandBarItems}
                        ariaLabel="Report actions"
                        primaryGroupAriaLabel="Report actions"
                        farItemsGroupAriaLabel="More actions"
                    />
                </Stack>
                <Text variant="xLarge" style={{marginTop: '2rem'}}>Messages</Text>
                <MessageStatsQueryLoading>
                    <Loading /> 
                </MessageStatsQueryLoading>
                <MessageStatsQueryLoaded>
                    <ConsolidationReportChart />
                </MessageStatsQueryLoaded>

                <Separator />

                <div style={forceFullWidth ? forceFullWidthStyle : allowSplitWidthStyle }>
                    <Stack style={{ minWidth: '400px', flex: '1 1 0'}}>
                        <Text variant="xLarge" style={{marginTop: '2rem'}}>Journey count</Text>
                        <MessageStatsQueryLoading>
                            <Loading /> 
                        </MessageStatsQueryLoading>
                        <MessageStatsQueryLoaded>
                            <JourneyCountChart />
                        </MessageStatsQueryLoaded>          
                    </Stack> 

                    <Stack style={{ minWidth: '400px', flex: '1 1 0'}}>
                        <Text variant="xLarge" style={{marginTop: '2rem'}}>Latencies</Text>
                        <MessageStatsQueryLoading>
                            <Loading /> 
                        </MessageStatsQueryLoading>
                        <MessageStatsQueryLoaded>
                            <LatenciesChart />
                        </MessageStatsQueryLoaded>  
                    </Stack>
                </div>
            </Stack>
        </Page>
    )
}