import { MessageBar, MessageBarType } from "@fluentui/react";

type ErrorMsg = {
    error?: string;
    onDismiss?: () => void;
}

const ErrorBar: React.FunctionComponent<ErrorMsg> = (props) => {
    return props.error
    ? (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={false}
        dismissButtonAriaLabel="Close"
        onDismiss={props.onDismiss}
      >
        {props.error}          
      </MessageBar>
    ) 
    : (<></>)
  };

  export default ErrorBar;