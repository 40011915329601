import React, {useState} from 'react';
import { Badge } from './badge';
import './badge-list.css';

type Props = {
    variant: 'primary' | 'secondary' | 'danger';
    badges: string[];
} 

export const BadgeList: React.FunctionComponent<Props> = (props) => {

    const mapBadge = (badge: string) => {
        const text = (badge || '').trim();
        if (text.length == 0)
            return null;
        return <Badge key={badge} variant={props.variant}>{badge}</Badge>
    }

    return <div className="badge-list">{props.badges.map(mapBadge)}</div>
}