import { ITextFieldProps, TextField } from '@fluentui/react';
import React, { PropsWithChildren, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

export interface Props<TFieldValues extends FieldValues = FieldValues, TContext = any> extends ITextFieldProps {
    control: Control<TFieldValues, TContext>;
    initial?: any;
    name: string;
    updateOnKeyPress?: boolean;
    updateOnEnterPress?: boolean;
};

export const ControlledTextField: React.FunctionComponent<Props> = (props) => {

    const [value, setValue] = useState<any>(props.initial);

    return (
        <Controller 
            render = {({ field }) => (
                <TextField
                    onKeyDown={(e) => {
                        if (props.updateOnKeyPress || (props.updateOnEnterPress && (e.code == 'Enter' || e.code == 'NumPadEnter')))
                            setValue(e.currentTarget.value);
                            field.onChange(e.currentTarget.value);
                    }} 
                    {...props}
                    onChange={(e) => {
                        setValue(e.currentTarget.value);
                        field.onChange(e.currentTarget.value)
                    }}
                    value={field.value || value}
                />
            )}
            control={props.control}
            name={props.name}
        />
    );
};
