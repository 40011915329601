import { DefaultButton, IStackProps, MessageBar, MessageBarType, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ErrorBar from '../../components/errorBar';
import { AuthContext } from '../../contexts/auth-context';

type Props = {
    reset: boolean;
}

type FormValues = {
  email?: string;
  code?: string;
  password?: string;
  passwordConfirm?: string;
}

const gapStyles: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { marginTop: '1rem' } },
};

const VerifyAccount: React.FunctionComponent<Props> = (props) => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState('');

  const history = useNavigate();
  const authContext = useContext(AuthContext);

  const sendClicked = async (props: FormValues) => {
    if (busy)
      return;
    setBusy(true);
    try {
      await authContext.verifyCode(props.email, props.code)
      history('/signin')
    } catch (err) {
      setError('Invalid Code')
    }
    finally {
        setBusy(false);
    }
  }

  const passwordResetClicked = async () => {
    history('/forgot-password')
  }

  return (

    <Stack>
      <ErrorBar error={error} />
      <Text variant='large'>Verify your account</Text>
      <form onSubmit={handleSubmit(sendClicked)} style={{marginTop: '0rem'}} >
        <Stack {...gapStyles}>
          <TextField type="email" autoFocus {...register("email", { required: true, maxLength: 80 })} placeholder="Email" errorMessage={errors?.email?.message as string} />
          <TextField type="text" {...register("code", { required: true, maxLength: 6 })} placeholder="Code" errorMessage={errors?.code?.message as string} />
          <PrimaryButton type="submit" disabled={busy}>Verify account</PrimaryButton>
          <DefaultButton onClick={passwordResetClicked}>Resend code</DefaultButton>
          <DefaultButton onClick={() => history(-1)}>Cancel</DefaultButton>
        </Stack>
      </form>
    </Stack>
  );  
};

export default VerifyAccount;