export function roundToTwoDecimals(value: number, diviser: number): number {
    return Math.round(((value + Number.EPSILON) / diviser) * 100) / 100;
}

export function roundToZeroDecimals(value: number, diviser: number): number {
    return Math.round((value + Number.EPSILON) / diviser);
}

const kb = 1024;
const mb = 1024 * kb;
const gb = 1024 * mb;
const tb = 1024 * gb;

export function shortenBytes(value: number) {
    if ((value) > tb) {
        return `${roundToTwoDecimals(value, tb)} TB`;
    }
    if (value > gb) {
        return `${roundToTwoDecimals(value, gb)} GB`;
    }
    if (value > mb) {
        return `${roundToTwoDecimals(value, mb)} MB`;
    }
    if (value > kb) {
        return `${roundToZeroDecimals(value, kb)} MB`;
    }
    return `${roundToZeroDecimals(value, 1)} bytes`;
};

const K = 1000;
const M = 1000 * K;
const BN = 1000 * M;

export function shortenCount(value: number) {
    if (value > BN) {
        return `${roundToTwoDecimals(value, BN).toLocaleString("sv-SE")} BN`;
    }
    if (value > M) {
        return `${roundToTwoDecimals(value, M).toLocaleString("sv-SE")} M`;
    }
    if (value > K) {
        return `${roundToTwoDecimals(value, K).toLocaleString("sv-SE")} K`
    }
    return roundToZeroDecimals(value, 1);
}

export function formatCountAndPerCent(count?: number, totalDelivered?: number) {
    if (count && totalDelivered && totalDelivered > 0) {
        const perCent = roundToTwoDecimals(count / totalDelivered, 0.01);
        return shortenCount(count) + ` (${perCent} %)`;
    }
    return "";
}