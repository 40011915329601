import { ApolloError, gql, useQuery } from '@apollo/client';
import {
    DatePicker,
    DayOfWeek,
    defaultDatePickerStrings,
    Dropdown,
    getTheme,
    IDropdownOption,
    IIconProps,
    IStackProps,
    IStackStyles, mergeStyleSets,
    PrimaryButton,
    ProgressIndicator, Spinner,
    SpinnerSize,
    Stack,
    Text,
    Toggle,
    TooltipHost
} from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, IColumn, IDetailsListStyles, SelectionMode } from '@fluentui/react/lib/DetailsList';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { BadgeList } from '../../components/badges/badge-list';
import { ControlledTextField } from '../../components/forms/ControlledTextField';
import Placeholder from '../../components/placeholder';
import { getDateString } from '../../lib/date-utils';
import './journey-list.css';

type Props = {}

const theme = getTheme();

const styles: Partial<IDetailsListStyles> = {
    root: {
        // height: '100vh',
        padding: '2rem',
        flex: '1',
    }
};

const detailsListStyles: Partial<IDetailsListStyles> = {
    root: {
        'a': {
            color: theme.palette.themeSecondary,
            textDecoration: 'none'
        },
        'a:hover': {
            color: theme.palette.blueLight
        }
    }
};

const perCentageSpanStyles = {
    width: '65px',
    display: 'inline-block'
}

const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { a: '1' } },
};

const days: IDropdownOption[] = [
    { text: 'Sunday', key: DayOfWeek.Sunday },
    { text: 'Monday', key: DayOfWeek.Monday },
    { text: 'Tuesday', key: DayOfWeek.Tuesday },
    { text: 'Wednesday', key: DayOfWeek.Wednesday },
    { text: 'Thursday', key: DayOfWeek.Thursday },
    { text: 'Friday', key: DayOfWeek.Friday },
    { text: 'Saturday', key: DayOfWeek.Saturday },
];

const datePickerStyles: Partial<IStackStyles> = {
    root: {
        width: '240px'
    }
}

const filterIcon: IIconProps = { iconName: 'Filter' };

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px',
    },
};

type SearchValues = {
    operatingDay?: string;
    vehicleNumber?: string;
    lineNumber?: string;
    journeyNumber?: string;
}

interface FilterValues {
    operatingDay: string;
    vehicleNumber: string;
    lineNumber: number;
    journeyNumber: number;
}

const linkStyles = {
    color: theme.palette.blueLight
};

export interface IJourney {
    id: string;
    key: string;
    lineNumber: number;
    journeyNumber: number;
    vehicleNumber: string;
    direction: string;
    deviations: string;
    plannedDeparture: string;
    actualDeparture: string;
    plannedStopCount: number;
    actualStopCount: number;
    stopPerCentage: number;
    totalMessagesLogged: number;
    totalMessagesDelivered: number;
    totalMessagesInTime: number;
    positionsLate: number;
    stopsMade: number;
    sla: number;
    slaExpectedCount: number;
    slaActualCount: number;
    missingNonPositionMessages: number;
    messagesDeliveredInTimePerCent: number;
    overallP95Latency: number;
    status: string;
}

const JOURNEYS_QUERY = gql`
    query GetJourneys($operatingDay: String!, $vehicleNumber: String!, $lineNumber: Int!, $journeyNumber: Int!) {
        getJourneys(operatingDay: $operatingDay, vehicleNumber: $vehicleNumber, lineNumber: $lineNumber, journeyNumber: $journeyNumber) {
            journeys
            {
                id
                operatingDay
                vehicleNumber
                lineNumber
                journeyNumber
                totalMessagesLogged
                totalMessagesDelivered
                totalMessagesInTime
                positionsLate
                stopsMade
                sla
                slaExpectedCount
                slaActualCount
                missingNonPositionMessages
                overallP95Latency
                signOn
                signOff
                plannedDeparture
                actualDeparture
                deviations      
            }
        }
    }
`;

const ComputedProgress = (props: { item: IJourney }) => (
    <ProgressIndicator percentComplete={props.item.sla} className={props.item.status} />
);

type FilterFormProps = { 
    loading: boolean;
    defaultValues?: FilterValues;
    onSubmit: (value: SearchValues) => void;
}

const FilterForm: React.FunctionComponent<FilterFormProps> = (props) => {
    const { handleSubmit, control } = useForm();

    const zeroToEmptyString = (value: any) => value === 0 ? '' : value;

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} style={{ marginTop: '0rem' }} >
            <Stack horizontal {...columnProps}>
                <Controller
                    render={({ field }) => (
                        <DatePicker
                            onSelectDate={(date) => field.onChange(getDateString(date))}
                            value={(field.value && new Date(field.value)) || props.defaultValues && props.defaultValues?.operatingDay && new Date(props.defaultValues!.operatingDay) || new Date()}
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={true}
                            firstWeekOfYear={1}
                            showMonthPickerAsOverlay={true}
                            placeholder="Operating day"
                            ariaLabel="Operating day"
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            strings={defaultDatePickerStrings}
                            styles={datePickerStyles}
                        />
                    )}
                    control={control}
                    name="operatingDay"
                />

                <ControlledTextField name="vehicleNumber" iconProps={filterIcon} initial={zeroToEmptyString(props.defaultValues?.vehicleNumber)} placeholder="Vehicle number" updateOnEnterPress={true} control={control} />
                <ControlledTextField name="lineNumber" iconProps={filterIcon} placeholder="Line number" initial={zeroToEmptyString(props.defaultValues?.lineNumber)} updateOnEnterPress={true} control={control} />
                <ControlledTextField name="journeyNumber" iconProps={filterIcon} placeholder="Journey number" initial={zeroToEmptyString(props.defaultValues?.journeyNumber)} updateOnEnterPress={true} control={control} />

                <PrimaryButton type="submit" disabled={props.loading}>
                    Search
                </PrimaryButton>
            </Stack>
        </form>
    );
}

function determineState(item: IJourney) {
    let state;
    if (item.missingNonPositionMessages > 0)
        state = 'danger';
    else if (item.sla > 0.99) 
        state = 'okay';
    else if (item.sla > 0.95)
        state = "warn";
    else 
        state = "danger";
    return state;
}

function addStateToRows(rows: IJourney[]) {
    const patched = rows.map(d => ({...d, status: determineState(d)}));
    return patched;
}

const Loading = () => (<Spinner style={{ marginTop: '3rem' }} size={SpinnerSize.large} />);

const rowFilterOptions: IDropdownOption[] = [
    {
        key: 'all',
        text: 'Show all'
    },
    {
        key: 'all-deviations',
        text: 'Show deviations only',
        selected: true
    },
    {
        key: 'sla-violations',
        text: 'Show SLA violations'
    },
    {
        key: 'missing-messages',
        text: 'Show missing messages'
    },
    {
        key: 'not-logged',
        text: 'Show messages not logged'
    }

];

type HeaderProps = {
    rowFilter: string;
    rowCount: number;
    visibleRowCount: number;
    setRowFilter: (newValue: string) => void;
}
const Header: React.FunctionComponent<HeaderProps> = (props) => (
    <Stack horizontal style={{margin: '0.75rem 0px 0 0', alignItems: 'baseline', columnGap: '7rem'}}>
        <Dropdown
            style={{ margin: '0', marginBottom: '0', width: '240px' }}
            placeholder="Show"
            options={rowFilterOptions}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                props.setRowFilter((option?.key || 'all-deviations') as string);
            }}
        />        
        <Text>
            <strong>{props.rowCount}</strong> journeys found. Showing <strong>{props.visibleRowCount}</strong> rows.
        </Text>
    </Stack>  
);

const formatRowCount = (rowCount: number) => rowCount == 1 ? 'row is' : 'rows are';
const noRecordsToShowOrNoRecordsReturned = (rowCount: number) => rowCount == 0 ? 'No journeys were matched.' : 'No records to show.';

const NoRows = (props: { rowCount: number }) => (
    <Placeholder>
        {noRecordsToShowOrNoRecordsReturned(props.rowCount)} {props.rowCount > 0 ? (
            <strong>
                <br />
                <br />
                {props.rowCount} {formatRowCount(props.rowCount)} hidden.
            </strong>) : null}
    </Placeholder>
);

type JourneyListQueryProps = {
    filter: FilterValues;
    onData: (data: IJourney[]) => void;
    onError: (error: ApolloError) => void;
    onLoading: (loading: boolean) => void;
};

const JourneyListQuery: React.FunctionComponent<JourneyListQueryProps> = (props) => {
    const { error, observable } = useQuery(JOURNEYS_QUERY, {
        variables: {
            operatingDay: props.filter.operatingDay || '',
            vehicleNumber: props.filter.vehicleNumber || '',
            lineNumber: props.filter.lineNumber || 0,
            journeyNumber: props.filter.journeyNumber || 0
        }
    });

    useEffect(() => {
        console.log("UseEffect: %s", JSON.stringify(props));
        const subscription = observable.subscribe((d) => {
            if (d && d.data && !d.loading) {
                const _allRows = addStateToRows(d.data.getJourneys.journeys);
                props.onData(_allRows);
                props.onLoading(false);
            }
            else {
                props.onLoading(d.loading);
            }
        });

        return (() => {
            subscription.unsubscribe();
        });
    }, [props.filter]);

    return (<></>);
};

type JourneyListState = {
    columns: IColumn[];
    filter: FilterValues;
    allRows: IJourney[];
    data: IJourney[];
    loading: boolean;
    rowFilter: string;
};


// TODO: This needs to be converted to class that extends React.Component, in order for forward declarations to work, so that
// columns can reference onClick for sort method, before it's declared. Otherwise sort won't work.
export class JourneyList extends React.Component<Props, JourneyListState> {

    private defaultColumns: IColumn[] = [
        {
            key: 'lineNumber',
            name: 'Line',
            fieldName: 'lineNumber',
            minWidth: 80,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            data: 'number',
            isPadded: false,
        },
        {
            key: 'plannedDeparture',
            name: 'Planned',
            fieldName: 'plannedDeparture',
            minWidth: 80,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            data: 'string',
            isPadded: false,
            onRender: (item: IJourney) => (
                <Link style={{color: 'inherit !important'}} to={`/journey/${item.id}`}>{item.plannedDeparture}</Link>
            )
        },
        {
            key: 'actualDeparture',
            name: 'Departure',
            fieldName: 'actualDeparture',
            minWidth: 80,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            data: 'string',
            isPadded: false,
        },
        {
            key: 'vehicle',
            name: 'Vehicle',
            fieldName: 'vehicleNumber',
            minWidth: 80,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            data: 'string',
            isPadded: false,
        },
        {
            key: 'deviations',
            name: 'Deviations',
            fieldName: 'deviations',
            minWidth: 80,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            data: 'string',
            isPadded: false,
            onRender: (item: IJourney) => (<BadgeList variant='danger' badges={item.deviations.split(',')} />)
        },
        {
            key: 'stopsMade',
            name: 'Stops',
            fieldName: 'stopsMade',
            minWidth: 40,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            onRender: (item: IJourney) => (
                <span style={{ display: 'block', textAlign: 'right' }}>
                    {item.stopsMade}
                </span>
            ),
            /*
            <TooltipHost content={`${item.plannedStopCount} / ${item.actualStopCount}`}>
                    <Stack>
                        <span>{item.actualStopCount} / {item.plannedStopCount}</span>
                        <ProgressIndicator percentComplete={item.stopPerCentage} className={item.stopPerCentage == 1 ? 'okay' : 'danger'} />
                    </Stack>
                </TooltipHost>
            */
            data: 'number',
            isPadded: false,
        },
        {
            key: 'overallP95Latency',
            name: 'p95',
            fieldName: 'overallP95Latency',
            minWidth: 40,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            onRender: (item: IJourney) => (
                <span style={{ display: 'block', textAlign: 'right' }}>{item.overallP95Latency} ms</span>
            ),
            data: 'number',
            isPadded: false,
        },
        {
            key: 'messagesDelivered',
            name: 'Messages',
            fieldName: 'sla',
            minWidth: 210,
            maxWidth: 240,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this.onColumnClick.bind(this),
            onRender: (item: IJourney) => (
                <TooltipHost content={`Delivered: ${item.totalMessagesDelivered} | Logged: ${item.totalMessagesLogged} | Late positions: ${item.positionsLate} | Missing non-position: ${item.missingNonPositionMessages}`}>
                    <Stack>
                        <Stack horizontal>
                            <span style={perCentageSpanStyles}>{Math.trunc((item.sla * 1000)) / 10} %</span>
                            <span>{item.slaActualCount} / {item.slaExpectedCount}</span>
                        </Stack>
                        <ComputedProgress item={item} />
                    </Stack>
                </TooltipHost>
            ),
            data: 'number',
            isPadded: false,
        },

        /*{
            key: 'column1',
            name: 'File Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: this._onColumnClick,
            onRender: (item: IJourney) => (
                <TooltipHost content={`${item.fileType} file`}>
                    <span>{item.dateModified}</span>;
                </TooltipHost>
            ),
        },*/
    ];

    defaultState: JourneyListState = {
        allRows: [],
        columns: this.defaultColumns,
        data: [],
        filter: { operatingDay: getDateString(), vehicleNumber: '', lineNumber: 0, journeyNumber: 0 },
        loading: true,
        rowFilter: 'all-deviations'
    }

    constructor (props: Props) {
        super(props);        

        const previousQuery = window.sessionStorage.getItem('fleet-tracker-journey-list-query');
        const previousFilter = window.sessionStorage.getItem('fleet-tracker-journey-list-filter');
        const previousQuerySet = window.sessionStorage.getItem('fleet-tracker-journey-list-query-set');
        const previousFilterSet = window.sessionStorage.getItem('fleet-tracker-journey-list-filter-set');

        if (previousQuery && previousQuerySet && (new Date().getTime() - new Date(previousQuerySet).getTime() < 10 * 60 * 1000)) {
            const previousQueryObj = JSON.parse(previousQuery);
            previousQueryObj.lineNumber = +previousQueryObj.lineNumber;
            previousQueryObj.journeyNumber = +previousQueryObj.journeyNumber;
            this.defaultState.filter = previousQueryObj;            
        }

        if (previousFilter && previousFilterSet && (new Date().getTime() - new Date(previousFilterSet).getTime() < 10 * 60 * 1000)) {
            this.defaultState.rowFilter = previousFilter;
        }
    }

    componentDidMount(): void {
        console.log("Did mount: %s", JSON.stringify(this.defaultState.filter));
        this.setState((state) => this.defaultState);
    }

    onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
        const newColumns: IColumn[] = this.state.columns!.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort<IJourney>(this.getItems(), currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState((state) => ({ columns: newColumns, data: newItems }));            
    }    

    filterRowsAndSetComponentState(rows: IJourney[], rowFilter: string) {
        let filtered: IJourney[] = [];
       
        for (let row of rows) {
            switch (rowFilter) {
                case 'all-deviations':
                    if ((row.deviations || '') === '')
                        continue;
                    break;
                case 'sla-violations':
                    if (row.deviations.indexOf("SLA violation") < 0)
                        continue;
                    break;
                case 'missing-messages':
                    if (row.missingNonPositionMessages === 0)
                        continue;
                    break;
                case 'not-logged':
                    if (row.deviations.indexOf("Messages not logged") < 0)
                        continue;
                    break;
            }
            filtered.push(row);
        }

        this.setState((state) => ({ data: filtered }));
    };

    setRowFilter(rowFilter: string) {
        this.setState((state) => ({ rowFilter }));
        this.filterRowsAndSetComponentState(this.state.allRows, rowFilter);
        window.sessionStorage.setItem('fleet-tracker-journey-list-filter', JSON.stringify(rowFilter));
        window.sessionStorage.setItem('fleet-tracker-journey-list-filter-set', new Date().toISOString());
    }

    
    async search(values: SearchValues) {
        let operatingDay = getDateString(values.operatingDay ? new Date(values.operatingDay!) : this.defaultState.filter.operatingDay ? new Date(this.defaultState.filter.operatingDay) : new Date());
        let vehicleNumber: string = (values.vehicleNumber || this.defaultState.filter.vehicleNumber || '').trim();
        let lineNumber = tryParseInt(values.lineNumber);
        let journeyNumber = tryParseInt(values.journeyNumber);
        const filter = { filter: { operatingDay, vehicleNumber, lineNumber, journeyNumber } };
        this.setState((state) => (filter));

        window.sessionStorage.setItem('fleet-tracker-journey-list-query', JSON.stringify({
            operatingDay, 
            vehicleNumber: values.vehicleNumber, 
            lineNumber: values.lineNumber, 
            journeyNumber: values.journeyNumber 
        }));
        window.sessionStorage.setItem('fleet-tracker-journey-list-query-set', new Date().toISOString());
    }

    getItems() {
        if (this.state.data && !this.state.loading)
            return this.state.data;

        return [];
    };


    _getKey(item: any, index?: number): string {
        return item.id;
    }

    _onItemInvoked(item: any): void {
        // alert(`Item invoked: ${item.name}`);
    }

    onData(rows: IJourney[]) {
        this.setState((state) => ({ allRows: rows }));
        this.filterRowsAndSetComponentState(rows, this.state.rowFilter);
    }

    onLoading(loading: boolean) {
        this.setState((state) => ({ loading }));
    }

    render() {
        if (!this.state) {
            return (<Loading />);
        }
        return (
            <Stack styles={styles}>
                <Text variant="xxLarge" styles={{ root: { marginBottom: '1rem' } }}>Journeys</Text>
                <JourneyListQuery filter={(this.state || this.defaultState).filter} onData={this.onData.bind(this)} onError={() => {}} onLoading={this.onLoading.bind(this)}  />
                <FilterForm onSubmit={this.search.bind(this)} defaultValues={this.defaultState.filter} loading={(this.state || this.defaultState).loading} />
                {(this.state || this.defaultState).loading ? (<Loading />) : (
                    <Stack>
                        <Header rowCount={this.state.allRows.length} visibleRowCount={this.state.data.length} rowFilter={this.state.rowFilter} setRowFilter={this.setRowFilter.bind(this)} /> 
                        { (this.state.data || []).length == 0 ? <NoRows rowCount={this.state.allRows.length} /> : (
                            <div style={{ maxWidth: '1000px' }}>
                                <Stack>
                                    <DetailsList
                                        items={this.state.data || []}
                                        compact={false}
                                        columns={this.state.columns}
                                        selectionMode={SelectionMode.none}
                                        getKey={this._getKey}
                                        setKey="none"
                                        styles={detailsListStyles}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        onItemInvoked={this._onItemInvoked}
                                    />
                                </Stack>
                            </div>
                         )}
                    </Stack>
                )}
            </Stack>
        );    
    }
};

function tryParseInt(value: string | undefined): number {
    if (value && value.trim() !== '') {
        let temp = parseInt(value.trim());
        if (temp > 0)
            return temp;
    }
    return 0;
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}