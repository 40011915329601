import React, {useState} from 'react';
import './badge.css';

type Props = {
    variant: 'primary' | 'secondary' | 'danger';
    children?: React.ReactNode;
}


export const Badge: React.FunctionComponent<Props> = (props) => (
    <span className={`badge badge-${props.variant}`}>{props.children}</span>
);