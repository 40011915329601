import { Nav, initializeIcons, Stack, Toggle, Text, Icon, FontIcon } from '@fluentui/react';
import { useContext } from 'react';
import { AuthContext, IAuth } from '../contexts/auth-context';

const navigationStyles = {
  root: {
    // height: '100vh',
    width: '240px',
    padding: '1vh',
    boxSizing: 'border-box',
    borderRight: '1px solid #eee',
    overflowY: 'auto',
    paddingTop: '10vh',
    background: 'transparent !important'
  },
};

const brandStyles = {
  root: {
    padding: '1.5rem',
    display: 'block',
    'text-align': 'center',
    color: '#cc3333'
  }
}

const userStyles = {
  root: {
    paddingLeft: '1.5rem',
    fontSize: '32px',
    alignItems: 'center',
    marginTop: '24px'
  }
}

const userIconStyles = {
  fontSize: '18px',
  fontWeight: 'bold'
}

const userNameStyles = {
  root: {
    fontSize: '22px',
    marginLeft: '0.5rem'
  }
}

const links = [
  {
    links: [
      {
        name: 'Dashboard',
        key: 'dashboard',
        url: '/',
        iconProps: {
          iconName: 'BIDashboard',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },

      {
        name: 'Map',
        key: 'map',
        url: '/map',
        iconProps: {
          iconName: 'Nav2DMapView',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },
      {
        name: 'Vehicles',
        key: 'vehicles',
        url: '/vehicles',
        iconProps: {
          iconName: 'Bus',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },
      {
        name: 'Journeys',
        key: 'journeys',
        url: '/journeys',
        iconProps: {
          iconName: 'MapDirections',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },
      {
        name: 'Reports',
        key: 'reports',
        url: '/reports',
        iconProps: {
          iconName: 'BarChartVerticalFilter',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },
      {
        name: 'Settings',
        key: 'settings',
        url: '/',
        iconProps: {
          iconName: 'PlayerSettings',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          }
        }
      },
    ],
  },
];

type Props = {
  darkModeSelected: boolean;
  toggleThemePreference: () => void;
}

const Navigation = (props: Props) => {

  const { userInfo }: IAuth = useContext(AuthContext);
  const isLocal = window.location.host.split(':')[0] === 'localhost';

  return (
    <Stack>
      <Text styles={brandStyles} variant="xxLarge">Fleet Tracker</Text>
      <Stack horizontal styles={userStyles}>
        <FontIcon iconName="Contact" style={userIconStyles} />
        <Text styles={userNameStyles}>
          {userInfo?.given_name}
        </Text>
      </Stack>

      <Nav
        groups={links}
        selectedKey='key1'
        styles={navigationStyles}
      />

      { isLocal && (
      <Toggle
        //className={classes.toggle}
        inlineLabel
        label="Theme"
        defaultChecked={props.darkModeSelected}
        onText="Dark"
        offText="Light"
        onChange={props.toggleThemePreference}
      />)}
    </Stack>

  );
};

export default Navigation;

/*

*/